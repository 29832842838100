<template>
  <div id="app"
    :class="isCms ? 'cms' : ''"
    >
    <v-header/>
    <div id="contentBody"
      class="mx-auto">
      <router-view v-show="!isLoading"/>
    </div>
    <v-loader v-show="isLoading"/>
    <v-error v-show="viewErr"/>
    <v-waiting v-show="isWaiting"/>
    <v-alertback v-show="isAlerting"/>
  </div>
</template>

<script>
// v-header from
import Loader from '@/components/Loader.vue';
import Header from '@/components/Header.vue';
import vError from '@/components/Error.vue';
import Waiting from '@/components/Waiting.vue';
import AlertBack from '@/components/AlertBack.vue';

export default {
  name: 'app',
  components: {
    'v-loader': Loader,
    'v-header': Header,
    'v-error': vError,
    'v-waiting': Waiting,
    'v-alertback': AlertBack,
  },
  data() {
    return {
      isCms: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.page.loading;
    },
    viewErr() {
      return this.$store.state.page.error;
    },
    isWaiting() {
      return this.$store.state.page.waiting;
    },
    viewConfirm() {
      return this.$store.state.page.viewConfirm;
    },
    isAlerting() {
      return this.$store.state.page.alertBack;
    },
  },
  watch: {
    '$route': function (to, from) { // eslint-disable-line
      this.isCms = to.meta.page && (to.meta.page === 'cms' || to.meta.page === 'uni');
    },
  },
  mounted() {
    document.title = process.env.VUE_APP_WEB_TITLE;
  },
  methods: {
  },
};
</script>

<style>

@import "./assets/css/style.css";
@import "./assets/css/overwriteBootstrap.css";
@import "./assets/css/datepicker.css";

</style>

<style scoped>
  #mainWrap {
    min-width: 1280px;
    z-index: 4;
  }
</style>
