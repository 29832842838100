import axios from 'axios';
import Store from '@/store/index';
import Auth from '@/constants/auth';

const api = {
  successHandler(response) {
    return response;
  },

  async errorHandler(error) {
    const { status } = error.response;
    // ログイン認証エラー時はstorageのトークンを削除しリロード
    if (status === 401) {
      Store.dispatch('auth/logout');
      window.location.reload();
    }
    // 権限認証エラー時, 無効なリクエスト時は権限に応じたトップページへ飛ばす。
    if (status === 403) {
      if (error.response.data.msg) {
        window.alert(error.response.data.msg);
      }
      // 遷移指定がfalseでなければ遷移する
      if (error.response.data.locate !== false) {
        Store.dispatch('page/onLoading');
        if (Store.state.auth.userType === Auth.USER_TYPE.GAS) {
          window.location = '/top';
        } else if (Store.state.auth.userType === Auth.USER_TYPE.CMS
          || Store.state.auth.userType === Auth.USER_TYPE.SYS) {
          window.location = '/cms/top';
        } else if (Store.state.auth.userType === Auth.USER_TYPE.UNIVERSITY) {
          window.location = '/university/applications/list';
        } else {
          Store.dispatch('auth/logout');
          window.location.reload();
        }
      }

      return;
    }
    throw error;
  },

  login(url, params = {}) {
    const axiosInstance = axios.create();
    return axiosInstance.post(url, params);
  },

  send(url, rawParams = {}, $requireBlob = false) {
    if (typeof rawParams !== 'object') {
      console.error('パラメータはオブジェクトではありません');
      return false;
    }
    const params = rawParams;

    const option = {};
    if ($requireBlob) {
      option.responseType = 'blob';
    }

    const axiosInstance = axios.create(option);

    // エラー時共通処理
    axiosInstance.interceptors.response.use(
      response => this.successHandler(response),
      error => this.errorHandler(error),
    );

    const { auth } = Store.state;
    params.token = auth.token;
    params.login_id = auth.login_id;
    return axiosInstance.post(url, params);
  },

  sendForm(url, params = {}) {
    const formData = new FormData();
    const option = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    Object.keys(params).forEach((key) => {
      // FormDataで送る場合は、値nullは文字列nullとしてポストされる？のでnullの場合送信データに含めない。
      if (params[key] !== null) {
        formData.append(key, params[key]);
      }
    });
    const axiosInstance = axios.create(option);

    // エラー時共通処理
    axiosInstance.interceptors.response.use(
      response => this.successHandler(response),
      error => this.errorHandler(error),
    );

    const { auth } = Store.state;
    formData.append('token', auth.token);
    formData.append('login_id', auth.login_id);

    return axiosInstance.post(url, formData);
  },

  // token自体をテーブルに持って、認証に使う
  fetch(url, rawParams = {}) {
    const params = rawParams;
    const axiosInstance = axios.create();
    const { auth } = Store.state;
    params.token = auth.token;
    params.login_id = auth.login_id;
    params.token_expiration = auth.tokenExpiration;
    const headers = {
      Authorization: `Bearer ${auth.token}`,
    };
    return axiosInstance.get(url, {
      headers,
      params,
    });
  },

  all(methods = []) {
    return axios.all(methods);
  },
};

export default api;
