import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_CMS_USER = {
  // ユーザー情報
  inputData: {
    sei: null,
    mei: null,
    kana_sei: null,
    kana_mei: null,
    mail_address: null,
    affiliated_institution: null,
    position: null,
    password: null,
    password_twice: null,
    authes: [],
    changeEmail: false,
    changePassword: false,
  },
  errors: [],
  isSend: false,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_CMS_USER),
  mutations: {
    setDbData(state, user) {
      if (user) {
        state.inputData.sei = user.sei;
        state.inputData.mei = user.mei;
        state.inputData.kana_sei = user.kana_sei;
        state.inputData.kana_mei = user.kana_mei;
        state.inputData.mail_address = user.mail_address;
        state.inputData.affiliated_institution = user.affiliated_institution;
        state.inputData.position = user.position;
        state.inputData.password = user.password;
        state.inputData.password_twice = user.password_twice;
        state.inputData.authes = user.authes;
        state.inputData.changeEmail = user.changeEmail;
        state.inputData.changePassword = user.changePassword;
      }
    },
    // ユーザー情報
    updateFamilyName(state, value) {
      state.inputData.sei = value;
    },
    updateFirstName(state, value) {
      state.inputData.mei = value;
    },
    updateFamilyNameKana(state, value) {
      state.inputData.kana_sei = value;
    },
    updateFirstNameKana(state, value) {
      state.inputData.kana_mei = value;
    },
    updateMail_address(state, value) {
      state.inputData.mail_address = value;
    },
    updateAffiliated_institution(state, value) {
      state.inputData.affiliated_institution = value;
    },
    updatePosition(state, value) {
      state.inputData.position = value;
    },
    updatePassword(state, value) {
      state.inputData.password = value;
    },
    updatePassword_twice(state, value) {
      state.inputData.password_twice = value;
    },
    updateAuthes(state, value) {
      state.inputData.authes = value;
    },
    isChangeEmail(state, value) {
      state.inputData.changeEmail = value;
    },
    isChangePassword(state, value) {
      state.inputData.changePassword = value;
    },
    // エラー関連
    setErrorMsg(state, errors) {
      state.errors = errors;
    },
    addErrorMsg(state, errors) {
      Vue.set(state.errors, errors.key, errors.msg);
    },
    deleteErrMsg(state, target) {
      Vue.delete(state.errors, target);
    },
    requiredUpdateTimes(state) {
      let result = (
        state.inputData.sei
        && state.inputData.mei
        && state.inputData.kana_sei
        && state.inputData.kana_mei
      ) || false;

      if (state.inputData.changeEmail) result = (result && state.inputData.mail_address) || false;
      if (state.inputData.changePassword) {
        result = ((result && state.inputData.password === state.inputData.password_twice)
          && (state.inputData.password !== null && state.inputData.password !== '')
          && (state.inputData.password === state.inputData.password_twice)) || false;
      }
      state.isSend = result;
    },
  },
  actions: {
    resetErrorMsg({ commit }) {
      commit('setErrorMsg', []);
    },
    checkMarks({ commit }) {
      return commit('requiredUpdateTimes');
    },
    resetItem({ commit }, target) {
      if (!target.value) {
        if (target.name === 'email') {
          commit('updateMail_address', '');
          commit('deleteErrMsg', 'mail_address');
        }
        if (target.name === 'password') {
          commit('updatePassword', '');
          commit('updatePassword_twice', '');
          commit('deleteErrMsg', 'password');
          commit('deleteErrMsg', 'password_twice');
        }
      }
      commit('requiredUpdateTimes');
    },
    resetFlg({ commit }) {
      commit('isChangeEmail', false);
      commit('isChangePassword', false);
    },
  },
};
