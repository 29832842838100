import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  // 検索条件保存
  search: {
    year: null,
    name: '',
    series: null,
    code: '',
    status: '',
  },
  currentPage: 1,
  perPage: 50,
  totalRows: 0,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdIdList = filterdIdList;
    },
    setFilter(state, param) {
      Vue.set(state.search, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    initSearch(state) {
      state.search = {
        year: null,
        name: '',
        series: null,
        code: '',
        status: '',
      };
      state.currentPage = 1;
      state.perPage = 50;
      state.totalRows = 0;
    },
  },
  actions: {
  },
};
