
const AUTH = {
  GROUP: 1,
  CHAIRMAN: 2,
  EVALUATION: 3,
};

const AUTH_TEXT = {
  1: '財団管理者',
  2: '審査委員長',
  3: '審査委員',
};

const USER_TYPE = {
  GAS: 1,
  CMS: 2,
  UNIVERSITY: 3,
  SYS: 9,
};

const USER_META = {
  1: ['gas'],
  2: ['cms'],
  3: ['uni'],
  9: ['gas', 'cms'],
};

export default {
  AUTH,
  AUTH_TEXT,
  USER_TYPE,
  USER_META,
};
