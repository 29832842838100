<template>
  <div id="spinner">
    <div id="spinnerWrap">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Waiting',
};
</script>

<style>
  #spinner {
    z-index: 1000;
    position: fixed;
    background-color:rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
