import Vue from 'vue';
import Vuex from 'vuex';

// 権限、ページ制御等共通処理
import auth from '@/store/modules/auth';
import page from '@/store/modules/page';
import cmsUserSetting from '@/store/modules/cmsUserSetting';
import manteInfo from '@/store/modules/manteInfo';
// CMSコンテンツ管理
import validation from '@/store/modules/validationRules';
import cmsContentsField from '@/store/modules/cms/contents/field';
import cmsContentsApptype from '@/store/modules/cms/contents/apptype';
import cmsContentsApptypePage from '@/store/modules/cms/contents/apptypePage';

// CMS管理者
import cmsUser from '@/store/modules/cms/user/cmsUser';
import cmsUserSearch from '@/store/modules/cms/user/search';
import cmsSetting from '@/store/modules/cms/user/cmsSetting';

// CMSお知らせ
import cmsNews from '@/store/modules/cms/news/news';

// GASユーザー情報
import userInfo from '@/store/modules/userInfo';
import authInfo from '@/store/modules/gas/setting/authInfo';
import gasMessageSearch from '@/store/modules/gas/message/msgSearch';
// 一般画面申請・報告
import gasApplicationForm from '@/store/modules/gas/form/applicationForm';
import gasApplicationInput from '@/store/modules/gas/form/applicationInput';
import gasApplicationOldInput from '@/store/modules/gas/form/applicationOldInput';

// 管理画面申請
import cmsApplicationSearch from '@/store/modules/cms/applications/search';
import cmsApplicationControl from '@/store/modules/cms/applications/control';
import cmsMessageSearch from '@/store/modules/cms/applications/msgSearch';

// 計画変更申請
import cmsChangePlansSearch from '@/store/modules/cms/changePlans/search';
// 管理画面 助成手続き書類
import cmsRequiredDocSearch from '@/store/modules/cms/requiredDoc/search';
import cmsRequiredDocControl from '@/store/modules/cms/requiredDoc/control';
// 管理画面報告
import cmsReportSearch from '@/store/modules/cms/reports/search';
import cmsReportControl from '@/store/modules/cms/reports/control';

// 審査
import cmsEvaluationSearch from '@/store/modules/cms/evaluation/search';

// 合否
import cmsJudgmentSearch from '@/store/modules/cms/applications/judgeSearch';

// 申請フォーム管理
import cmsForm from '@/store/modules/cms/form/form';

// 大学関連
import cmsUniversityTieSearch from '@/store/modules/cms/university/tieSearch';
import cmsUniversitySearch from '@/store/modules/cms/university/search';

/**
 * 大学画面
 */
// 申請管理
import uniApplicationSearch from '@/store/modules/uni/applications/search';
import uniMessageSearch from '@/store/modules/uni/message/msgSearch';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    page,
    validation,
    cmsUserSetting,
    cmsContentsField,
    authInfo,
    userInfo,
    cmsUser,
    cmsUserSearch,
    cmsSetting,
    cmsNews,
    cmsContentsApptype,
    cmsContentsApptypePage,
    gasApplicationForm,
    gasApplicationInput,
    gasApplicationOldInput,
    gasMessageSearch,
    cmsApplicationSearch,
    cmsMessageSearch,
    cmsChangePlansSearch,
    cmsApplicationControl,
    cmsEvaluationSearch,
    cmsJudgmentSearch,
    cmsForm,
    cmsReportSearch,
    cmsReportControl,
    cmsUniversitySearch,
    cmsUniversityTieSearch,
    cmsRequiredDocSearch,
    cmsRequiredDocControl,
    // 大学画面
    uniApplicationSearch,
    uniMessageSearch,
    manteInfo,
  },
});
