import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_AUTH_INFO = {
  // 認証情報
  inputData: {
    passwordChangeFlg: false,
    newPassword: null,
    newPasswordConf: null,
    mailAddressChangeFlg: false,
    newMailAddress: null,
    verifyCode: null,
  },
  mailAddress: null,
  errors: [],
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_AUTH_INFO),
  mutations: {
    setDbData(state, auth) {
      // 認証情報
      if (auth) {
        state.mailAddress = auth.login_id;
      }
    },
    // 認証情報
    updatePasswordChangeFlg(state, value) {
      state.inputData.passwordChangeFlg = value;
    },
    updateNewPassword(state, value) {
      state.inputData.newPassword = value;
    },
    updateNewPasswordConf(state, value) {
      state.inputData.newPasswordConf = value;
    },
    updateMailAddressChangeFlg(state, value) {
      state.inputData.mailAddressChangeFlg = value;
    },
    updateNewMamilAddress(state, value) {
      state.inputData.newMailAddress = value;
    },
    updateVerifyCode(state, value) {
      state.inputData.verifyCode = value;
    },
    resetInputData(state) {
      state.inputData = cloneDeep(INIT_AUTH_INFO.inputData);
    },
    // エラー関連
    setErrorMsg(state, errors) {
      state.errors = errors;
    },
    addErrorMsg(state, errors) {
      Vue.set(state.errors, errors.key, errors.msg);
    },
    deleteErrMsg(state, target) {
      Vue.delete(state.errors, target);
    },
    resetErrorMsg(state) {
      state.errors = [];
    },
  },
  actions: {
  },
};
