<template>
  <div>
    <div class="badgeWrap">
      <div v-if="isRequired" class="mr-2 badge badge-required">必須</div>
    </div>
    <label
      v-if="!noLabel"
      :class="labelSize">{{ label }}</label>
      <b-form-textarea
        :placeholder="placeholder"
        :value="value"
        :size="size"
        :state="null"
        :rows="rows"
        :maxlength="maxlength"
        :maxRows="maxRows"
        @input="$emit('input', $event)"
      >
      </b-form-textarea>
  </div>
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    label: { type: String },
    placeholder: { type: String },
    labelSize: { type: String, default: '' },
    size: { type: String, default: '' },
    value: {},
    err: {},
    rows: { type: Number, default: 3 },
    maxlength: { type: String, default: '' },
    maxRows: { type: Number, default: 6 },
    noLabel: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
label.small{
  font-size: 12px;
  margin-bottom: 15px;
}
label {
  color: #6F7579;
}

</style>
