
const USECASE = {
  // 氏名
  NAME: 1,
  KANA: 2,
  // 誕生日
  BIRTH: 3,
  // 年齢
  AGE: 4,
  // 所属機関
  BELONGS: 5,
  // 文理区分
  BUNRI: 6,
  // 性別
  SEX: 7,
  // 共同研究者
  COWORKER: 8,
  // タイトル（２０字程度の研究テーマや会議の名前を想定）
  TITLE: 9,
  // 詳細(200字以内の研究要旨や会議の趣旨を想定)
  DETAIL: 10,
  // 助成期間
  TERM: 11,
  // 希望額
  MONEY: 12,
  // 所属2 学科とか
  BELONGS_2: 13,
  // 職位
  POSITION: 14,
  // 申請団体
  APPLYORG: 16,
};

export default {
  USECASE,
};
