// import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  filterdAppIdList: [],
  selectedRepIdList: [],
  selectedAppidList: [],
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.repId;
      });
      state.filterdIdList = filterdIdList;
    },
    setFilterdAppIdList(state, filterdData) {
      const filterdAppIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdAppIdList = filterdAppIdList;
    },
    setFilterdAppIdAndNoList(state, filterdData) {
      const filterdAppIdList = filterdData.map((data) => {
        return `${data.id}_${data.sort}`;
      });
      state.filterdAppIdList = filterdAppIdList;
    },
    removeSelectedAppid(state, appId) {
      const index = state.selectedAppidList.indexOf(appId);
      if (index !== -1) {
        state.selectedAppidList.splice(index, 1);
      }
    },
    removeSelectedRepId(state, repId) {
      const index = state.selectedRepIdList.indexOf(repId);
      if (index !== -1) {
        state.selectedRepIdList.splice(index, 1);
      }
    },
    setSelectedAppid(state, list) {
      state.selectedAppidList = list;
    },
    addSelectedAppid(state, appId) {
      if (state.selectedAppidList.indexOf(appId) === -1) {
        state.selectedAppidList.push(appId);
      }
    },
    addSelectedRepId(state, repId) {
      if (state.selectedRepIdList.indexOf(repId) === -1) {
        state.selectedRepIdList.push(repId);
      }
    },

    allSelectedAppid(state) {
      state.selectedAppidList = state.filterdAppIdList.slice();
      state.selectedRepIdList = state.filterdIdList.slice();
    },
    initSelectedAppid(state) {
      state.selectedAppidList = [];
      state.selectedRepIdList = [];
    },
    initRepId(state) {
      state.selectedRepIdList = [];
    },
  },
  actions: {
  },
};
