<template>
  <b-container fluid>
    <b-row :class="itemStyle">
      <b-col sm="3" :class="labelGridClass">
        <label :class="labelSize">{{ label }}</label>
      </b-col>
      <b-col sm="9">
        <b-input-group>
          <b-form-select
            :value="value"
            :options="options"
            :size="size"
            :class="inputClass"
            :state="null"
            @input="$emit('input', $event)"
            @change="$emit('change')"
          >
            <template v-slot:first v-if="hasDefault">
              <option :value="defaultValue">{{defaultText}}</option>
            </template>
          </b-form-select>
          <b-input-group-append
            v-if="suffix !== ''"
            class="ml-1 flex flexMiddle">
            {{suffix}}
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'PartsFormSelect',
  props: {
    label: { type: String },
    itemStyle: { default: 'w-small' },
    value: {},
    inputClass: {},
    labelSize: { type: String, default: '' },
    size: { type: String, default: '' },
    labelGridClass: { default: 'labelWrap' },
    options: { type: [Array, Object] },
    err: {},
    noLabel: { type: Boolean, default: false },
    hasDefault: { type: Boolean, default: true },
    defaultText: { type: String, default: '-- 選択してください。 --' },
    defaultValue: { type: [String, Number], default: null },
    suffix: { type: String, default: '' },
  },
};
</script>

<style scoped>
label,
.labelWrap {
  width: 100%;
  max-width: 135px;
}

select {
  width: 400px;
  height: 50px;
}

/* ラベル + 横並びテキストタイプ(itemStyle = "line") */
.line .labelWrap {
  margin-right: 32px;
}
.line{
  align-items: center;
  margin:10px auto;
}
.line label{
  align-items:center;
  margin:0;
}
.line label{
  text-align: left;
}
.line span{
  padding-left: 22%;
  text-align: left;
}

label.small{
  font-size: 12px;
  margin-bottom: 15px;
}
label {
  color: #6F7579;
}
.backText {
  color: #6F7579;
}

/* テキストサイズ(long or small) */
select[type="text"].w-long {
  width: 750px
}
select[type="text"].w-small {
  width: 190px
}
</style>
