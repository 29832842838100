import Vue from 'vue';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import USECASE from '@/constants/usecase';

function _searchTargetIndex(array, param) {
  let foundIndex;
  array.find((input, index) => {
    const sameField = input.field_id === param.field_id;
    const sameOption = input.option_id === param.option_id;
    const sameAnswerNo = input.answer_no === param.answer_no;
    if (sameField && sameOption && sameAnswerNo) {
      foundIndex = index;
    }
    return sameField && sameOption && sameAnswerNo;
  });
  return foundIndex;
}

function _getAnswer(array, param) {
  return array.find((input) => {
    const sameField = input.field_id === param.field_id;
    const sameOption = input.option_id === param.option_id;
    const sameAnswerNo = input.answer_no === param.answer_no;
    return sameField && sameOption && sameAnswerNo;
  });
}

function _getCheckBoxAnswer(array, param) {
  const answers = [];
  array.forEach((input) => {
    const sameField = input.field_id === param.field_id;
    const sameOption = input.option_id === param.option_id;
    if (sameField && sameOption) {
      answers.push(String(input.field_answer_id));
    }
  });
  return answers;
}
function _searchTargetFieldIndex(array, param) {
  let foundIndex;
  array.find((input, index) => {
    const sameField = input.field_id === param.field_id;
    if (sameField) {
      foundIndex = index;
    }
    return sameField;
  });
  return foundIndex;
}
/**
 * field_id: maxAnswerNumの形のObjectを返す
 * @param {*} array
 */
function getMaxAnswerNums(answers) {
  const maxAnswerNums = {};
  answers.forEach((answer) => {
    const nowMaxAnswer = maxAnswerNums[answer.field_id] || 0;
    if (nowMaxAnswer < answer.answer_no) {
      maxAnswerNums[answer.field_id] = answer.answer_no;
    }
  });
  return maxAnswerNums;
}

const INIT_INPUTS = {
  page_field_id: null,
  field_id: null,
  option_id: null,
  answer_no: null,
  field_answer_id: null, // 選択式の時の解答欄
  field_answer_text: '', // 入力式の時の解答欄
  checkbox_answers: [],
  uploadedFileKey: '', // ファイルの時のキー
  uploadedFileName: '',
  dbFileName: null,
  do_delete_file: false,
  errMessages: [],
};

export default {
  namespaced: true,
  state: {
    inputs: [cloneDeep(INIT_INPUTS)],
    oldInputs: [cloneDeep(INIT_INPUTS)],
    files: {},
    maxAnswerNums: {},
    code: '',
    interimNo: '',
    changeReasonInputs: [],
    answer_no: 1,
    // 一時アップロードファイル入れ
    tempUpFileList: {},
    upFileKeys: {},
  },
  mutations: {
    setDbData(state, dbData) {
      // 初期化
      state.inputs = [];
      state.oldInputs = [];
      state.files = {};
      state.maxAnswerNums = {};
      state.code = '';
      state.interimNo = '';
      state.answer_no = 1;
      const { pages: dbPages, answers } = dbData;
      const isEdit = answers && answers.length !== 0;
      if (isEdit) {
        state.maxAnswerNums = getMaxAnswerNums(answers);
      }
      // if (answers !== null) {
      //   state.maxAnswerNums = getMaxAnswerNums(answers);
      // }
      // ページのループ
      dbPages.forEach((dbPage) => {
        // フィールドのループ
        dbPage.fields.forEach((field) => {
          // 通常はinput_numberの設定値だけ取得する
          // ただしすでに登録されているデータがそれより多い場合は、データ分入れ物を作る
          let inputNum = field.input_number;

          // 新規登録の場合はここでmaxInputNumsを、input_numberを元に作る
          if (!isEdit) {
            Vue.set(state.maxAnswerNums, field.field_id, field.input_number);
          } else if (inputNum < state.maxAnswerNums[field.field_id]) {
            // 編集登録の場合は、登録済みの回答数が初期値より多ければ、登録済みの方を初期値にする。
            inputNum = state.maxAnswerNums[field.field_id];
          }
          // オプションのループ
          field.options.forEach((option) => {
            const input = {
              field_id: field.field_id,
              option_id: option.id,
              answer_no: null,
              field_answer_id: null,
              // switchの場合は初期値は0
              field_answer_text: option.input_type === 11 ? 0 : '',
              checkbox_answers: [],
              uploadedFileKey: '',
              uploadedFileName: '',
              dbFileName: null,
              do_delete_file: false,
              errMessages: [],
            };
            // チェックボックス以外
            if (option.input_type !== 7) {
              for (let answerNo = 1; answerNo <= inputNum; answerNo += 1) {
                input.answer_no = answerNo;
                // field_id-option_id-answer_noの一意キーを作成
                input.id = `${field.field_id}-${option.id}-${answerNo}`;
                if (answers !== null) {
                  const dbInput = _getAnswer(answers, input);
                  input.field_answer_id = null;
                  input.field_answer_text = option.input_type === 11 ? 0 : '';
                  input.checkbox_answers = [];
                  input.dbFileName = null;
                  if (typeof dbInput !== 'undefined') {
                    input.field_answer_id = dbInput.field_answer_id;
                    input.field_answer_text = dbInput.field_answer_text;
                    input.checkbox_answers = dbInput.checkbox_answers || [];
                    input.dbFileName = dbInput.file_path;
                  }
                }
                state.inputs.push(cloneDeep(input));
              }
            } else {
              // チェックボックスのときは、複数の回答を1回答番号の中に配列で収める
              input.answer_no = 1;
              input.id = `${field.field_id}-${option.id}-1`;
              input.checkbox_answers = [];
              if (isEdit) {
                input.checkbox_answers = _getCheckBoxAnswer(answers, input);
              }
              state.inputs.push(cloneDeep(input));
            }
          });
        });
      });
    },
    resetValue(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const target = state.inputs[foundIndex];
      Vue.set(target, 'field_answer_text', '');
      Vue.set(target, 'field_answer_id', null);
      Vue.set(target, 'checkbox_answers', []);
    },
    oldCopy(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      if (typeof foundIndex !== 'undefined') {
        const target = state.inputs[foundIndex];
        Vue.set(target, 'field_answer_text', cloneDeep(param.old_data.field_answer_text));
        Vue.set(target, 'field_answer_id', cloneDeep(param.old_data.field_answer_id));
        if (param.old_data.dbFileName) {
          Vue.set(target, 'dbFileName', cloneDeep(param.old_data.dbFileName));
          Vue.set(target, 'uploadedFileKey', cloneDeep(param.old_data.uploadedFileKey));
          Vue.set(target, 'uploadedFileName', null);
        }
      } else {
        state.inputs.push(cloneDeep(param.old_data));
      }
    },
    setText(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const target = state.inputs[foundIndex];
      Vue.set(target, 'field_answer_text', param.value);
    },
    setSelect(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const target = state.inputs[foundIndex];
      Vue.set(target, 'field_answer_id', param.value);
    },
    setFile(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const key = `file_${foundIndex}`;
      state.inputs[foundIndex].uploadedFileKey = key;
      state.inputs[foundIndex].uploadedFileName = param.file ? param.file.name : '';
      Vue.set(state.files, key, param.file);
      if (state.upFileKeys[key] === undefined) {
        Vue.set(state.upFileKeys, key, []);
      }
      // 一時アップロードファイル
      const myUpFileKeys = state.upFileKeys[key];
      myUpFileKeys[0] = 1;
      Vue.set(state.tempUpFileList, key, {});
    },
    setAnsNum(state, value) {
      state.answer_no = value;
    },
    deleteFile(state, param) {
      const foundIndex = _searchTargetIndex(state.inputs, param);
      const key = `file_${foundIndex}`;
      state.inputs[foundIndex].uploadedFileKey = '';
      state.inputs[foundIndex].uploadedFileName = '';
      Vue.delete(state.files, key);
    },
    addInputs(state, params) {
      params.forEach((param) => {
        const input = {
          field_id: param.field_id,
          option_id: param.option_id,
          answer_no: param.answer_no,
          field_answer_id: null,
          field_answer_text: '',
          checkbox_answers: [],
          uploadedFileKey: '',
          uploadedFileName: '',
          dbFileName: null,
          do_delete_file: false,
          errMessages: [],
        };
        // チェックボックスの時、値上書き
        if (param && Object.prototype.hasOwnProperty.call(param, 'init_value') && param.input_type === 11) {
          input.field_answer_text = 0;
        }
        state.inputs.push(input);
      });
    },
    removeInputs(state, param) {
      let newInputs = cloneDeep(state.inputs);
      newInputs = newInputs.filter((input) => {
        const isSameField = input.field_id === param.fieldId;
        const isSameLine = input.answer_no === param.line;
        return !(isSameField && isSameLine);
      });
      newInputs = newInputs.map((input) => {
        const reduceInput = input;
        const isSameField = input.field_id === param.fieldId;
        if (isSameField && input.answer_no > param.line) {
          reduceInput.answer_no -= 1;
        }
        return reduceInput;
      });
      state.inputs = newInputs;
    },
    toggleDoDeleteDbFile(state, input) {
      const doDeleteFile = _getAnswer(state.inputs, input).do_delete_file;
      const index = _searchTargetIndex(state.inputs, input);
      Vue.set(state.inputs[index], 'do_delete_file', !doDeleteFile);
    },
    initErrMessages(state) {
      state.inputs.forEach((input, index) => {
        state.inputs[index].errMessages = [];
      });
    },
    setErrMessages(state, errMessages) {
      state.inputs.forEach((input, index) => {
        state.inputs[index].errMessages = [];
        if (errMessages[index]) {
          state.inputs[index].errMessages = errMessages[index];
        }
      });
      state.changeReasonInputs.forEach((input, index) => {
        state.changeReasonInputs[index].errMessages = [];
      });
      Object.keys(errMessages).forEach((key) => {
        if (key.indexOf('reasons_') !== -1) {
          const fieldId = Number(key.substr(key.indexOf('_') + 1));
          let setedMsgId = '';
          state.changeReasonInputs.forEach((input, index) => {
            if (input.field_id === fieldId) {
              state.changeReasonInputs[index].errMessages = errMessages[key];
              setedMsgId = key;
            }
          });
          if (setedMsgId === '') {
            const reasonInput = {
              field_id: fieldId,
              reason: '',
              errMessages: errMessages[key],
            };
            state.changeReasonInputs.push(cloneDeep(reasonInput));
          }
        }
      });
    },
    setCode(state, value) {
      state.code = value;
    },
    setInterimNo(state, value) {
      state.interimNo = value;
    },
    setMaxAnswerNums(state, param) {
      Vue.set(state.maxAnswerNums, param.fieldId, param.newLineNum);
    },
    setUserInfo(state, dbData) {
      // eslint-disable-next-line
      const { pages: dbPages, userInfo } = dbData;
      const usecase = USECASE.USECASE;
      // ページのループ
      let birthDay;
      dbPages.forEach((dbPage) => {
        // フィールドのループ
        dbPage.fields.forEach((field) => {
          // 名前
          if (field.usecase === usecase.NAME) {
            const values = [userInfo.sei, userInfo.mei];
            let index = 0;
            field.options.forEach((option) => {
              const param = {
                field_id: field.field_id,
                option_id: option.id,
                answer_no: 1,
              };
              const foundIndex = _searchTargetIndex(state.inputs, param);
              const target = state.inputs[foundIndex];
              Vue.set(target, 'field_answer_text', values[index]);
              index += 1;
            });
          }
          // 名前（かな）
          if (field.usecase === usecase.KANA) {
            const values = [userInfo.kana_sei, userInfo.kana_mei];
            let index = 0;
            field.options.forEach((option) => {
              const param = {
                field_id: field.field_id,
                option_id: option.id,
                answer_no: 1,
              };
              const foundIndex = _searchTargetIndex(state.inputs, param);
              const target = state.inputs[foundIndex];
              Vue.set(target, 'field_answer_text', values[index]);
              index += 1;
            });
          }
          // 所属
          if (field.usecase === usecase.BELONGS) {
            const value = userInfo.institution;
            field.options.forEach((option) => {
              const param = {
                field_id: field.field_id,
                option_id: option.id,
                answer_no: 1,
              };
              const foundIndex = _searchTargetIndex(state.inputs, param);
              const target = state.inputs[foundIndex];
              Vue.set(target, 'field_answer_text', value);
            });
          }
          // 生年月日
          if (field.usecase === usecase.BIRTH) {
            birthDay = userInfo.birth;
            field.options.forEach((option) => {
              const param = {
                field_id: field.field_id,
                option_id: option.id,
                answer_no: 1,
              };
              const foundIndex = _searchTargetIndex(state.inputs, param);
              const target = state.inputs[foundIndex];
              Vue.set(target, 'field_answer_text', birthDay);
            });
          }
          if (field.usecase === usecase.AGE) {
            const birth = moment(new Date(birthDay));
            const age = moment().diff(birth, 'years');
            field.options.forEach((option) => {
              const param = {
                field_id: field.field_id,
                option_id: option.id,
                answer_no: 1,
              };
              const foundIndex = _searchTargetIndex(state.inputs, param);
              const target = state.inputs[foundIndex];
              Vue.set(target, 'field_answer_text', age);
            });
          }
        });
      });
    },
    setDbDataNoPage(state, dbData) {
      // 初期化
      state.inputs = [];
      state.files = {};
      state.maxAnswerNums = {};
      state.code = '';
      state.interimNo = '';
      state.changeReasonInputs = [];
      state.answer_no = 1;
      const { fields, answers } = dbData;
      const isEdit = answers && answers.length !== 0;
      const changeReasons = dbData.reasons || [];
      if (isEdit) {
        state.maxAnswerNums = getMaxAnswerNums(answers);
      }
      // フィールドのループ
      fields.forEach((field) => {
        // 通常はinput_numberの設定値だけ取得する
        // ただしすでに登録されているデータがそれより多い場合は、データ分入れ物を作る
        let inputNum = field.input_number;

        // 編集登録の場合は、登録済みの回答数が初期値より多ければ、登録済みの方を初期値にする。
        if (isEdit && state.maxAnswerNums[field.field_id] && inputNum < state.maxAnswerNums[field.field_id]) {
          inputNum = state.maxAnswerNums[field.field_id];
        } else {
          // 新規登録の場合はここでmaxInputNumsを、input_numberを元に作る
          Vue.set(state.maxAnswerNums, field.field_id, field.input_number);
        }
        // オプションのループ
        field.options.forEach((option) => {
          const input = {
            field_id: field.field_id,
            option_id: option.id,
            answer_no: null,
            field_answer_id: null,
            field_answer_text: '',
            checkbox_answers: [],
            uploadedFileKey: '',
            uploadedFileName: '',
            dbFileName: null,
            do_delete_file: false,
            errMessages: [],
          };
          // チェックボックス以外
          if (option.input_type !== 7) {
            for (let answerNo = 1; answerNo <= inputNum; answerNo += 1) {
              input.answer_no = answerNo;
              // field_id-option_id-answer_noの一意キーを作成
              input.id = `${field.field_id}-${option.id}-${answerNo}`;
              if (answers && answers.length !== 0) {
                const dbInput = _getAnswer(answers, input);
                input.field_answer_id = null;
                input.field_answer_text = option.input_type === 11 ? 0 : '';
                input.checkbox_answers = [];
                input.dbFileName = null;
                if (typeof dbInput !== 'undefined') {
                  input.field_answer_id = dbInput.field_answer_id;
                  input.field_answer_text = dbInput.field_answer_text;
                  input.checkbox_answers = dbInput.checkbox_answers || [];
                  input.dbFileName = dbInput.file_path;
                }
              }
              state.inputs.push(cloneDeep(input));
            }
          } else {
            // チェックボックスのときは、複数の回答を1回答番号の中に配列で収める
            input.answer_no = 1;
            input.id = `${field.field_id}-${option.id}-1`;
            input.checkbox_answers = [];
            if (answers && answers.length !== 0) {
              input.checkbox_answers = _getCheckBoxAnswer(answers, input);
            }
            state.inputs.push(cloneDeep(input));
          }
        });
        // 変更理由保存用
        if (field.is_change_reason) {
          let setReason = '';
          if (changeReasons.length > 0) {
            const foundIndex = _searchTargetFieldIndex(changeReasons, { field_id: field.field_id });
            setReason = typeof foundIndex === 'undefined' ? '' : changeReasons[foundIndex].reason;
          }
          const reasonInput = {
            field_id: field.field_id,
            reason: setReason,
            errMessages: [],
          };
          state.changeReasonInputs.push(cloneDeep(reasonInput));
        }
      });
    },
    setReason(state, param) {
      const foundIndex = _searchTargetFieldIndex(state.changeReasonInputs, param);
      const target = state.changeReasonInputs[foundIndex];
      Vue.set(target, 'reason', param.value);
    },
    saveTempPath(state, params) {
      if (state.tempUpFileList[params.target] === undefined) {
        Vue.set(state.tempUpFileList, params.target, {});
      }
      Vue.set(state.tempUpFileList[params.target], params.subKey, params.path);
    },
  },
  actions: {
  },
};
