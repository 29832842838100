<template>
  <div class="menuLabel flex flexRight flexMiddle">
    <b-nav-item to="/cms/top"
      :class="menuName === 'cmsTop' ? 'activeNav' : ''"
      v-if="group">トップ</b-nav-item>

    <b-nav-item
      @click="initGo('/cms/cmsUser/list', 'cmsUserSearch')"
      :class="menuName === 'cmsUser' ? 'activeNav' : ''"
      v-if="group">管理者</b-nav-item>

    <b-nav-item-dropdown text="指定校"
      :class="menuName === 'cmsUniversity' ? 'activeNav' : ''"
      v-if="group">
      <b-dropdown-item
        @click="initGo('/cms/university/list', 'cmsUniversitySearch')">登録大学</b-dropdown-item>
      <b-dropdown-item to="/cms/university/tie/list">ユーザー紐付け</b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item to="/cms/user/gas/list"
      :class="menuName === 'cmsUserGas' ? 'activeNav' : ''"
      v-if="group">マイページ作成者</b-nav-item>

    <b-nav-item-dropdown text="申請・評価設定"
      :class="menuName === 'cmsForm' ? 'activeNav' : ''"
      v-if="group">
      <b-dropdown-item to="/cms/form/edit">申請・評価設定</b-dropdown-item>
      <b-dropdown-item
        to="/cms/applications/assign/edit">審査担当割当</b-dropdown-item>
      <b-dropdown-item
        @click="initGo('/cms/applications/judgment/list', 'cmsJudgmentSearch')">合否設定</b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item-dropdown text="各種申請"
      :class="menuName === 'cmsApplication' ? 'activeNav' : ''"
      v-if="group">
      <b-dropdown-item
        @click="initGo('/cms/applications/list', 'cmsApplicationSearch')">助成申請</b-dropdown-item>
      <b-dropdown-item
        @click="initGo('/cms/applications/change/list', 'cmsChangePlansSearch')">計画変更申請</b-dropdown-item>
      <b-dropdown-item to="/cms/applications/change/log/list">計画変更履歴</b-dropdown-item>
      <b-dropdown-item to="/cms/applications/mailLog/list">メール送信履歴</b-dropdown-item>
      <b-dropdown-item
        @click="initGo('/cms/applications/message/inbox', 'cmsMessageSearch')">メッセージ送受信</b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item-dropdown text="審査"
      :class="menuName === 'cmsEvaluation' ? 'activeNav' : ''"
      v-if="evaluation || group || chairman">
      <b-dropdown-item
        v-if="group|| chairman"
        to="/cms/evaluation/view">審査一覧</b-dropdown-item>
      <b-dropdown-item
        v-if="evaluation"
        to="/cms/evaluation/list">審査入力</b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item-dropdown text="提出書類"
      :class="menuName === 'cmsReport' ? 'activeNav' : ''"
      v-if="group || chairman">
      <b-dropdown-item to="/cms/requiredDoc/list">助成手続き書類</b-dropdown-item>
      <b-dropdown-item to="/cms/interimReps/list">進捗状況報告書</b-dropdown-item>
      <b-dropdown-item to="/cms/reports/list">成果報告書</b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item to="/cms/news"
      :class="menuName === 'cmsNews' ? 'activeNav' : ''"
      v-if="group">お知らせ</b-nav-item>
    <b-nav-item
      :class="menuName === 'cmsSetting' ? 'activeNav' : ''"
      to="/cms/setting">ログイン情報変更</b-nav-item>

    <span
      class="logout px-3"
      @click="kickLogout"
      >ログアウト</span>
  </div>
</template>

<script>
import CONST_AUTH from '@/constants/auth';

export default {
  name: 'CmsHeader',
  props: {
    menuName: { type: String, default: '' },
  },
  computed: {
    // 財団管理者
    group() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.GROUP);
    },
    // 評価担当者
    evaluation() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.EVALUATION);
    },
    // 審査委員長
    chairman() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.CHAIRMAN);
    },
  },
  methods: {
    initGo(path, store) {
      if (this.$route.path !== path) {
        this.$store.commit(`${store}/initSearch`);
        this.$router.push(path);
      }
    },
    kickLogout() {
      this.$emit('kickLogout');
    },
  },
};
</script>

<style scoped>
  .logout {
    border: solid;
    border-width: 0 0 0 1px;
    border-color: #ccc;
  }

  .logout:hover {
    cursor: pointer;
  }

  .menuLabel>li{
    list-style: none;
  }

</style>
