export default {
  namespaced: true,
  state: {
    on_help_validation: true,
  },
  mutations: {
    setAll(state, storage) {
      Object.keys(storage).forEach((key) => {
        state[key] = storage[key];
      });
    },
    setHelp4ValidationRule(state, bool) {
      state.on_help_validation = bool;
    },
  },
  actions: {
    setHelp4ValidationRule(context, bool) {
      context.commit('setHelp4ValidationRule', bool);
      localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_SETTING_KEY,
        JSON.stringify(context.state));
    },
    setInitSetting(context) {
      const jsonStorage = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_SETTING_KEY);
      const storage = jsonStorage !== null ? JSON.parse(jsonStorage) : {};
      context.commit('setAll', storage);
    },
  },
};
