<template>
  <div class="menuLabel flex flexRight flexMiddle">
    <b-nav-item to="/top"
      :class="menuName === 'gasTop' ? 'activeNav' : ''">
      トップ</b-nav-item>
    <b-nav-item-dropdown text="メッセージ"
      :class="menuName === 'message' ? 'activeNav' : ''"
      :toggle-class="/message/.test($route.name) ? 'active' : ''">
      <b-dropdown-item to="/message/create">メッセージ作成</b-dropdown-item>
      <b-dropdown-item
       @click="initGo('/message/inbox', 'gasMessageSearch')">送受信一覧</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item-dropdown text="マイページ"
      :class="menuName === 'mypage' ? 'activeNav' : ''"
      :toggle-class="/gasMy/.test($route.name) ? 'active' : ''">
      <b-dropdown-item to="/myInfo">ユーザー情報</b-dropdown-item>
      <b-dropdown-item to="/myAuth">認証情報</b-dropdown-item>
      <b-dropdown-item to="/record">申請履歴</b-dropdown-item>
    </b-nav-item-dropdown>
    <span
      @click="kickLogout"
      class="logout px-3"
      >ログアウト</span>
  </div>
</template>

<script>
export default {
  name: 'GasHeader',
  props: {
    menuName: { type: String, default: '' },
  },
  methods: {
    initGo(path, store) {
      this.$store.commit(`${store}/initSearch`);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    kickLogout() {
      this.$emit('kickLogout');
    },
  },
};
</script>

<style scoped>
  .logout {
    border: solid;
    border-width: 0 0 0 1px;
    border-color: #ccc;
  }

  .logout:hover {
    cursor: pointer;
  }

  .menuLabel>li{
    list-style: none;
  }

</style>
