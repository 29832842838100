import Vue from 'vue';
import moment from 'moment';
import { cloneDeep } from 'lodash';

const INIT_NEWS = {
  // お知らせ
  inputData: {
    id: null,
    from: moment().format('YYYY/MM/DD HH:mm:00'),
    to: null,
    news: '',
    setDataTo: false,
  },
  maxLength: 500,
  errors: [],
  isSend: false,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_NEWS),
  mutations: {
    setDbData(state, news) {
      if (news) {
        state.inputData.id = news.id;
        state.inputData.from = news.from;
        state.inputData.to = news.to;
        state.inputData.news = news.news;
        state.inputData.setDataTo = news.setDataTo;
      }
    },
    // 更新時のみidが入る
    editPage(state, value) {
      state.inputData.id = value;
    },
    // お知らせ
    updateFrom(state, value) {
      const t = moment(value, 'YYYY/MM/DD HH:mm:ss').isValid() ? value : null;
      if (t === null) state.isSend = false;
      state.inputData.from = t;
    },
    updateTo(state, value) {
      const t = moment(value, 'YYYY/MM/DD HH:mm:ss').isValid() ? value : null;
      state.inputData.to = t;
    },
    updateNews(state, value) {
      state.inputData.news = value;
    },
    isSetDataTo(state, value) {
      state.inputData.setDataTo = value;
      // チェックを外したとき
      if (!state.inputData.setDataTo) state.inputData.to = null;
    },
    // エラー関連
    setErrorMsg(state, errors) {
      state.errors = errors;
    },
    addErrorMsg(state, errors) {
      Vue.set(state.errors, errors.key, errors.msg);
    },
    deleteErrMsg(state, target) {
      Vue.delete(state.errors, target);
    },
    requiredData(state) {
      state.isSend = (
        state.inputData.from
        && state.inputData.news
        && state.inputData.news.length <= state.maxLength
      ) || false;
    },
  },
  actions: {
    init({ commit }) {
      commit('setDbData', INIT_NEWS.inputData);
    },
    resetErrorMsg({ commit }) {
      commit('setErrorMsg', []);
    },
    checkMarks({ commit, state }) {
      state.isSend = true;
      // 必須項目・文字数チェック
      commit('requiredData', state);
      commit('deleteErrMsg', 'to');

      if (!state.inputData.setDataTo) return;
      if (!state.inputData.to || !moment(state.inputData.to, 'YYYY/MM/DD HH:mm:ss').isValid()) {
        commit('addErrorMsg', {
          key: 'to',
          msg: ['掲載終了日を入力してください。'],
        });
        state.isSend = false;
      }
      if (moment(state.inputData.from).format('YYYY/MM/DD HH:mm:00')
        >= moment(state.inputData.to).format('YYYY/MM/DD HH:mm:00')) {
        commit('addErrorMsg', {
          key: 'to',
          msg: ['掲載終了日は掲載開始日以降の日時を選択してください。'],
        });
        state.isSend = false;
      }
    },
    editNews({ commit, state }, news) {
      if (news.item.id) {
        // 編集時、初期送信可能に統一。
        state.isSend = true;
        commit('editPage', news.item.id);
        commit('updateFrom', news.item.dataFrom);
        commit('updateNews', news.item.news);
        if (news.item.dataTo !== null) {
          commit('updateTo', news.item.dataTo);
          commit('isSetDataTo', true);
        }
      } else {
        commit('setDbData', INIT_NEWS.inputData);
      }
    },
  },
};
