import Vue from 'vue';
import VueRouter from 'vue-router';

import Store from '@/store/index';

import Auth from '@/constants/auth';
import api from '@/modules/api';

Vue.use(VueRouter);

const routes = [
  // public
  {
    path: '/user/register/initMail',
    name: 'gasRegisterInit',
    component: () => import('../views/RegisterInit.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/register/user/form/:uuid',
    name: 'gasRegisterForm',
    component: () => import('../views/RegisterForm.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/login',
    name: 'gasLogin',
    component: () => import('../views/GasLogin.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/cms/login',
    name: 'cmsLogin',
    component: () => import('../views/CmsLogin.vue'),
    meta: {
      isPublic: true,
      page: 'public',
    },
  },
  {
    path: '/identify/password/recover',
    name: 'passwordRecover',
    component: () => import('../views/passwordRecover.vue'),
    meta: {
      isPublic: true,
      page: 'public',
      back: 'gasLogin',
    },
  },
  {
    path: '/cms/identify/password/recover',
    name: 'cmsPasswordRecover',
    component: () => import('../views/passwordRecover.vue'),
    meta: {
      isPublic: true,
      page: 'public',
      back: 'cmsLogin',
    },
  },
  // 大学ユーザー紐付け
  // /university/tie/2/34325725f8ceb52e84fd
  {
    path: '/university/tie/:appId/:token',
    name: 'tieTop',
    component: () => import('../views/tie/TieTop.vue'),
    meta: {
      isPublic: true,
      page: 'public',
      back: 'gasLogin',
    },
  },
  {
    path: '/university/tie/init/:appId/:token',
    name: 'tieInit',
    component: () => import('../views/tie/TieInit.vue'),
    meta: {
      isPublic: true,
      page: 'public',
      back: 'gasLogin',
    },
  },
  {
    path: '/university/tie/form/:appId/:token/:uuid',
    name: 'tieForm',
    component: () => import('../views/tie/TieForm.vue'),
    meta: {
      isPublic: true,
      page: 'public',
      back: 'gasLogin',
    },
  },
  /*
  |--------------------------------------------------------------------------
  | 一般画面
  |--------------------------------------------------------------------------
  */
  {
    path: '/top',
    name: 'gasTop',
    component: () => import('../views/gas/Top.vue'),
    meta: {
      page: 'gas',
      menu: 'gasTop',
    },
  },
  {
    path: '/myInfo',
    name: 'gasMyParentInfo',
    component: () => import('../views/gas/MyInfo.vue'),
    meta: {
      page: 'gas',
      menu: 'mypage',
    },
  },
  {
    path: '/myAuth',
    name: 'gasMyAuth',
    component: () => import('../views/gas/MyAuth.vue'),
    meta: {
      page: 'gas',
      menu: 'mypage',
    },
  },
  {
    path: '/record',
    name: 'gasRecord',
    component: () => import('../views/gas/Record.vue'),
    meta: {
      page: 'gas',
      menu: 'mypage',
    },
  },
  // メッセージページ
  {
    path: '/message/create',
    name: 'gasMessageCreate',
    component: () => import('../views/gas/message/Create.vue'),
    meta: {
      page: 'gas',
      menu: 'message',
      type: 'new',
    },
  },
  {
    path: '/message/inbox/replying/:msgId',
    name: 'gasMessageInboxReception',
    component: () => import('../views/gas/message/Create.vue'),
    meta: {
      page: 'gas',
      menu: 'message',
      type: 'replying',
    },
  },
  {
    path: '/message/inbox',
    name: 'gasMessageInbox',
    component: () => import('../views/gas/message/Inbox.vue'),
    meta: {
      page: 'gas',
      menu: 'message',
    },
  },
  {
    path: '/message/inbox/detail/:msgId',
    name: 'gasMessageInboxDetail',
    component: () => import('../views/gas/message/Detail.vue'),
    meta: {
      page: 'gas',
      menu: 'message',
      detail: 'inbox',
    },
  },
  // {
  //   path: '/message/outbox',
  //   name: 'gasMessageOutbox',
  //   component: () => import('../views/gas/message/Outbox.vue'),
  //   meta: {
  //     page: 'gas',
  //     menu: 'message',
  //   },
  // },
  // {
  //   path: '/message/outbox/detail/:msgId',
  //   name: 'gasMessageOutboxDetail',
  //   component: () => import('../views/gas/message/Detail.vue'),
  //   meta: {
  //     page: 'gas',
  //     menu: 'message',
  //     detail: 'outbox',
  //   },
  // },
  // 申請ページ
  {
    path: '/application/:apptypeId',
    name: 'gasApplication',
    component: () => import('../views/gas/application/Init.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 申請フォーム
  {
    path: '/application/form/:apptypeId',
    name: 'gasApplicationForm',
    component: () => import('../views/gas/application/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 申請終了
  {
    path: '/application/end',
    name: 'gasApplicationEnd',
    component: () => import('../views/gas/application/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 計画変更申請 フォーム
  {
    path: '/change/form/:appId/:sort',
    name: 'gasChangeForm',
    component: () => import('../views/gas/change/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  {
    path: '/change/end',
    name: 'gasChangeEnd',
    component: () => import('../views/gas/change/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 進捗状況報告
  {
    path: '/interim/form/:appId/:sort',
    name: 'gasImterimRepForm',
    component: () => import('../views/gas/imterimRep/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 進捗状況報告終了
  {
    path: '/interim/end',
    name: 'gasImterimRepEnd',
    component: () => import('../views/gas/imterimRep/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 報告フォーム
  {
    path: '/report/form/:appId',
    name: 'gasReportForm',
    component: () => import('../views/gas/report/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 報告終了
  {
    path: '/report/end',
    name: 'gasReportEnd',
    component: () => import('../views/gas/report/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 助成手続き書類提出フォーム
  {
    path: '/requiredDoc/form/:appId',
    name: 'gasRequiredDocForm',
    component: () => import('../views/gas/requiredDoc/Form.vue'),
    meta: {
      page: 'gas',
    },
  },
  // 助成手続き書類提出終了
  {
    path: '/requiredDoc/end',
    name: 'gasRequiredDocEnd',
    component: () => import('../views/gas/requiredDoc/End.vue'),
    meta: {
      page: 'gas',
    },
  },
  /*
  |--------------------------------------------------------------------------
  | 管理画面
  |--------------------------------------------------------------------------
  */
  {
    path: '/cms/top',
    name: 'cmsTop',
    component: () => import('../views/cms/Top.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsTop',
    },
  },
  // CMSユーザー管理
  {
    path: '/cms/cmsUser/list',
    name: 'cmsUser',
    component: () => import('../views/cms/cmsUser/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUser',
    },
  },
  {
    path: '/cms/cmsUser/create',
    name: 'cmsUserCreate',
    component: () => import('../views/cms/cmsUser/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUser',
    },
  },
  {
    path: '/cms/cmsUser/edit/:cms_user_id',
    name: 'cmsUserEdit',
    component: () => import('../views/cms/cmsUser/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUser',
    },
  },
  // 申請管理 メール送信
  {
    path: '/cms/cmsUser/mail',
    name: 'cmsUserMail',
    component: () => import('../views/cms/cmsUser/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUser',
    },
  },
  // お知らせ
  {
    path: '/cms/news',
    name: 'cmsNews',
    component: () => import('../views/cms/News.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsNews',
    },
  },
  // 一般ユーザー管理
  {
    path: '/cms/user/gas/list',
    name: 'cmsUserGasList',
    component: () => import('../views/cms/user/gas/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUserGas',
    },
  },
  // 申請管理 一覧
  {
    path: '/cms/applications/list',
    name: 'cmsApplicationsList',
    component: () => import('../views/cms/applications/applications/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/list/:year/:apptypeId',
    name: 'cmsApplicationsListWithParam',
    component: () => import('../views/cms/applications/applications/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/edit/:appId',
    name: 'cmsApplicationsEdit',
    component: () => import('../views/cms/applications/applications/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 申請管理 メール送信
  {
    path: '/cms/applications/mail',
    name: 'cmsApplicationsMail',
    component: () => import('../views/cms/applications/applications/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 計画変更申請
  // 計画変更申請 一覧
  {
    path: '/cms/applications/change/list',
    name: 'cmsApplicationsChangeList',
    component: () => import('../views/cms/applications/change/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 計画変更申請 編集
  {
    path: '/cms/applications/change/edit/:changePlanId',
    name: 'cmsApplicationsChangeEdit',
    component: () => import('../views/cms/applications/change/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 計画変更履歴
  // 計画変更履歴 一覧
  {
    path: '/cms/applications/change/log/list',
    name: 'cmsApplicationsChangeLogList',
    component: () => import('../views/cms/applications/change/log/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // 計画変更申請 編集
  {
    path: '/cms/applications/change/log/view/:appId',
    name: 'cmsApplicationsChangeLogView',
    component: () => import('../views/cms/applications/change/log/View.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // メール送信履歴
  // メール送信履歴 一覧
  {
    path: '/cms/applications/mailLog/list',
    name: 'cmsApplicationsMailLogList',
    component: () => import('../views/cms/applications/mailLog/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  // メッセージ受信 一覧
  {
    path: '/cms/applications/message/inbox',
    name: 'cmsApplicationsMessageInboxList',
    component: () => import('../views/cms/applications/message/Inbox.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
    },
  },
  {
    path: '/cms/applications/message/inbox/detail/:msgId',
    name: 'cmsApplicationsMessageInboxDetail',
    component: () => import('../views/cms/applications/message/Detail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
      detail: 'inbox',
    },
  },
  {
    path: '/cms/applications/message/inbox/replying/:msgId',
    name: 'cmsApplicationsMessageInboxReception',
    component: () => import('../views/cms/applications/message/Create.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsApplication',
      type: 'replying',
    },
  },
  // 審査担当割当
  {
    path: '/cms/applications/assign/edit',
    name: 'cmsApplicationsAssignEdit',
    component: () => import('../views/cms/applications/assign/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsForm',
    },
  },
  // 管理者設定
  {
    path: '/cms/setting',
    name: 'cmsSetting',
    component: () => import('../views/cms/setting/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsSetting',
    },
  },
  // 審査
  {
    path: '/cms/evaluation/list',
    name: 'cmsEvaluationList',
    component: () => import('../views/cms/evaluation/evaluation/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsEvaluation',
    },
  },
  {
    path: '/cms/evaluation/list/:year/:apptypeId/:stageId',
    name: 'cmsEvaluationListWithParam',
    component: () => import('../views/cms/evaluation/evaluation/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsEvaluation',
    },
  },
  {
    path: '/cms/evaluation/view',
    name: 'cmsEvaluationView',
    component: () => import('../views/cms/evaluation/evaluation/View.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsEvaluation',
    },
  },
  // 合否判定 judgment
  {
    path: '/cms/applications/judgment/list',
    name: 'cmsApplicationsJudgmentList',
    component: () => import('../views/cms/applications/judgment/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsForm',
    },
  },
  {
    path: '/cms/applications/judgment/edit/:appId/:stageId',
    name: 'cmsApplicationsJudgmentEdit',
    component: () => import('../views/cms/applications/judgment/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsForm',
    },
  },
  {
    path: '/cms/applications/judgment/mail',
    name: 'cmsApplicationsJudgmentMail',
    component: () => import('../views/cms/applications/judgment/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsForm',
    },
  },
  // 期間設定
  {
    path: '/cms/form/edit',
    name: 'cmsFormEdit',
    component: () => import('../views/cms/form/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsForm',
    },
  },
  {
    path: '/cms/form/period/:year',
    name: 'cmsFormPeriod',
    component: () => import('../views/cms/form/Period.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsForm',
    },
  },
  // 助成手続き書類 一覧
  {
    path: '/cms/requiredDoc/list',
    name: 'cmsRequiredDocList',
    component: () => import('../views/cms/reports/requiredDoc/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  {
    path: '/cms/requiredDoc/list/:year/:seriesId',
    name: 'cmsRequiredDocListWithParam',
    component: () => import('../views/cms/reports/requiredDoc/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  {
    path: '/cms/requiredDoc/edit/:requiredDocId',
    name: 'cmsRequiredDocEdit',
    component: () => import('../views/cms/reports/requiredDoc/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  // 助成手続き書類 メール送信
  {
    path: '/cms/requiredDoc/mail',
    name: 'cmsRequiredDocMail',
    component: () => import('../views/cms/reports/requiredDoc/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  // 成果報告管理 一覧
  {
    path: '/cms/reports/list',
    name: 'cmsReportsList',
    component: () => import('../views/cms/reports/reports/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  {
    path: '/cms/reports/list/:year/:seriesId',
    name: 'cmsReportsListWithParam',
    component: () => import('../views/cms/reports/reports/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  {
    path: '/cms/reports/edit/:repId',
    name: 'cmsReportsEdit',
    component: () => import('../views/cms/reports/reports/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  // 成果報告管理 メール送信
  {
    path: '/cms/reports/mail',
    name: 'cmsReportsMail',
    component: () => import('../views/cms/reports/reports/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  // 進捗状況報告管理 一覧
  {
    path: '/cms/interimReps/list',
    name: 'cmsInterimRepsList',
    component: () => import('../views/cms/reports/interim/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  {
    path: '/cms/interimReps/edit/:repId',
    name: 'cmsInterimRepsEdit',
    component: () => import('../views/cms/reports/interim/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  // 進捗状況報告管理 メール送信
  {
    path: '/cms/interimReps/mail',
    name: 'cmsInterimRepsMail',
    component: () => import('../views/cms/reports/interim/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsReport',
    },
  },
  // 登録大学
  {
    path: '/cms/university/list',
    name: 'cmsUniversityList',
    component: () => import('../views/cms/university/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUniversity',
    },
  },
  {
    path: '/cms/university/create',
    name: 'cmsUniversityCreate',
    component: () => import('../views/cms/university/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUniversity',
    },
  },
  {
    path: '/cms/university/edit/:userId',
    name: 'cmsUniversityEdit',
    component: () => import('../views/cms/university/Edit.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUniversity',
    },
  },
  {
    path: '/cms/university/mail',
    name: 'cmsUniversityMail',
    component: () => import('../views/cms/university/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUniversity',
    },
  },
  // 一般ユーザーと申請の紐付けを行う
  {
    path: '/cms/university/tie/list',
    name: 'cmsUserTieList',
    component: () => import('../views/cms/university/tie/List.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUniversity',
    },
  },
  {
    path: '/cms/university/tie/mail',
    name: 'cmsUserTieMail',
    component: () => import('../views/cms/university/tie/Mail.vue'),
    meta: {
      page: 'cms',
      menu: 'cmsUniversity',
    },
  },
  /*
  |--------------------------------------------------------------------------
  | 大学申請画面関連
  |--------------------------------------------------------------------------
  */
  // {
  //   path: '/university/register/form',
  //   name: 'universityRegisterForm',
  //   component: () => import('../views/university/tie/Form.vue'),
  //   meta: {
  //     isPublic: true,
  //     page: 'uni',
  //   },
  // },
  // 申請管理
  {
    path: '/university/applications/list',
    name: 'universityApplicationList',
    component: () => import('../views/university/applications/List.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniApplication',
    },
  },
  {
    path: '/university/applications/create/:year/:seriesId',
    name: 'universityApplicationCreate',
    component: () => import('../views/university/applications/Edit.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniApplication',
    },
  },
  {
    path: '/university/applications/edit/:appId',
    name: 'universityApplicationEdit',
    component: () => import('../views/university/applications/Edit.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniApplication',
    },
  },
  // 計画変更申請
  {
    path: '/university/applications/change/list',
    name: 'universityApplicationChangeList',
    component: () => import('../views/university/change/List.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniApplication',
    },
  },
  {
    path: '/university/applications/change/edit/:appId/:sort',
    name: 'universityApplicationChangeForm',
    component: () => import('../views/university/change/Edit.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniApplication',
    },
  },
  // 助成手続き書類
  {
    path: '/university/requiredDoc/list',
    name: 'universityRequiredDocList',
    component: () => import('../views/university/requiredDoc/List.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniReport',
    },
  },
  {
    path: '/university/requiredDoc/edit/:appId',
    name: 'universityRequiredDocEdit',
    component: () => import('../views/university/requiredDoc/Edit.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniReport',
    },
  },
  // 進捗状況報告書
  {
    path: '/university/interimReps/list',
    name: 'universityInterimList',
    component: () => import('../views/university/interims/List.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniReport',
    },
  },
  {
    path: '/university/interimReps/edit/:appId/:sort',
    name: 'universityInterimEdit',
    component: () => import('../views/university/interims/Edit.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniReport',
    },
  },
  // 報告書
  {
    path: '/university/reports/list',
    name: 'universityReportList',
    component: () => import('../views/university/reports/List.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniReport',
    },
  },
  {
    path: '/university/reports/edit/:appId',
    name: 'universityReportEdit',
    component: () => import('../views/university/reports/Edit.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniReport',
    },
  },
  // 設定
  {
    path: '/university/settings/edit',
    name: 'universitySetting',
    component: () => import('../views/university/settings/Edit.vue'),
    meta: {
      isPublic: false,
      page: 'uni',
      menu: 'uniSetting',
    },
  },
  // メッセージページ
  {
    path: '/university/message/create',
    name: 'universityMessageCreate',
    component: () => import('../views/university/message/Create.vue'),
    meta: {
      page: 'uni',
      menu: 'message',
      type: 'new',
    },
  },
  {
    path: '/university/message/inbox/replying/:msgId',
    name: 'universityMessageInboxCreate',
    component: () => import('../views/university/message/Create.vue'),
    meta: {
      page: 'uni',
      menu: 'message',
      type: 'replying',
    },
  },
  {
    path: '/university/message/inbox',
    name: 'universityMessageInbox',
    component: () => import('../views/university/message/Inbox.vue'),
    meta: {
      page: 'uni',
      menu: 'message',
    },
  },
  {
    path: '/university/message/inbox/detail/:msgId',
    name: 'universityMessageInboxDetail',
    component: () => import('../views/university/message/Detail.vue'),
    meta: {
      page: 'uni',
      menu: 'message',
      detail: 'inbox',
    },
  },
  // {
  //   path: '/university/message/outbox',
  //   name: 'universityMessageOutbox',
  //   component: () => import('../views/university/message/Outbox.vue'),
  //   meta: {
  //     page: 'uni',
  //     menu: 'message',
  //   },
  // },

  // {
  //   path: '/university/message/outbox/detail/:msgId',
  //   name: 'universityMessageOutboxDetail',
  //   component: () => import('../views/university/message/Detail.vue'),
  //   meta: {
  //     page: 'uni',
  //     menu: 'message',
  //     detail: 'outbox',
  //   },
  // },
  // メンテナンス画面
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/Maintenance.vue'),
    meta: {
      isPublic: true,
      page: 'mante',
    },
  },
];

/**
 * Storeに認証データがない場合に、ストレージからデータの取得を行う。
 */
const checkExistTokenFromStoreOrStrage = () => {
  let { token } = Store.state.auth;
  if (!Store.state.auth.token) {
    const jsonStorage = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY);
    const storage = jsonStorage !== null ? JSON.parse(jsonStorage) : {};
    const {
      token: storageToken,
      login_id: loginId,
      userType,
      authes,
    } = storage;
    token = storageToken || null;
    const params = {
      login_id: loginId,
      token,
      userType,
      authes,
    };
    Store.dispatch('auth/login', params);
  }

  return (token !== null && token !== '');
};
const checkMante = async () => {
  const res = await api.send('/api/checkMante', {})
    .catch(() => {
      return false;
    });
  if (res !== false) {
    return res.data.isMante;
  }
  return false;
};
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});
router.beforeEach(async (to, from, next) => {
  if (!Store.state.manteInfo.isInit) {
    const isMante = await checkMante();
    Store.dispatch('manteInfo/isInit');
    if (isMante) {
      Store.dispatch('manteInfo/onMante');
    } else {
      Store.dispatch('manteInfo/offnMante');
    }
  }
  // メンテナンスフラグを確認
  const isGeneralMaintena = Store.state.manteInfo.isMante;
  const MANTE_PUBLIC_TARGETS = process.env.VUE_APP_MANTE_PUBLIC_TARGETS;
  const MANTE_TARGET_GROUP = process.env.VUE_APP_MANTE_TARGET_GROUP;
  if (isGeneralMaintena && (MANTE_PUBLIC_TARGETS.includes(to.name) || MANTE_TARGET_GROUP.includes(to.meta.page))) {
    next('/maintenance');
    return;
  }
  if (isGeneralMaintena === false && to.name === 'maintenance') {
    next('/login');
    return;
  }
  const isPublic = to.matched.some(page => page.meta.isPublic);
  // ページ遷移時はajax系の二重操作防止のwaitingをオフにしてあげる
  Store.dispatch('page/offWaiting');
  // 次のページがpublic設定であれば、そのまま遷移する。
  if (isPublic) {
    Store.dispatch('page/create', to.meta.page);
    next();
    return;
  }

  // 次のページがpublicでなければ、トークンチェックを行う。
  const doneLogin = checkExistTokenFromStoreOrStrage();

  // ログインしていなければ要求ページのmeta情報に合わせた遷移を行う
  // メタ情報は、public, gas, cms, 未定義（事実上の404）の4種
  if (!doneLogin) {
    console.error('no login');
    // 要求されたページをstoreに保存し、ログイン後に用いる。
    Store.commit('page/setDirectUrl', to.path);
    Store.dispatch('page/create', to.meta.page);
    if (to.meta.page === 'cms' || to.meta.page === 'uni') {
      next('/cms/login');
    } else {
      next('/login');
    }
    return;
  }

  // ログインしていて、かつ遷移先のURLが未定義の場合と、一般ユーザーが管理画面、管理ユーザーが一般画面を見ようとすると、ユーザータイプごとに振り分けたTOPへ移動する
  const { userType } = Store.state.auth;
  const metaOk = Auth.USER_META[userType].indexOf(to.meta.page) !== -1;
  if (typeof to.meta.page === 'undefined' || !metaOk) {
    // ログイン済の場合はユーザータイプごとに切り分ける;
    let path;
    if (userType === Auth.USER_TYPE.CMS) {
      path = '/cms/top';
    } else if (userType === Auth.USER_TYPE.GAS) {
      path = '/top';
    } else if (userType === Auth.USER_TYPE.UNIVERSITY) {
      path = '/university/applications/list';
    }
    Store.dispatch('page/create', to.meta.page);
    next({ path });
    return;
  }

  Store.dispatch('page/create', to.meta.page);
  Store.dispatch('cmsUserSetting/setInitSetting');

  next();
});

export default router;
