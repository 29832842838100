// import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  filterdAppIdList: [],
  selectedRequiredDocIdList: [],
  selectedAppidList: [],
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.requiredDocId;
      });
      state.filterdIdList = filterdIdList;
    },
    setFilterdAppIdList(state, filterdData) {
      const filterdAppIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdAppIdList = filterdAppIdList;
    },
    removeSelectedAppid(state, appId) {
      const index = state.selectedAppidList.indexOf(appId);
      if (index !== -1) {
        state.selectedAppidList.splice(index, 1);
      }
    },
    removeSelectedRequiredDocId(state, requiredDocId) {
      const index = state.selectedRequiredDocIdList.indexOf(requiredDocId);
      if (index !== -1) {
        state.selectedRequiredDocIdList.splice(index, 1);
      }
    },
    setSelectedAppid(state, list) {
      state.selectedAppidList = list;
    },
    addSelectedAppid(state, appId) {
      if (state.selectedAppidList.indexOf(appId) === -1) {
        state.selectedAppidList.push(appId);
      }
    },
    addSelectedRequiredDocId(state, requiredDocId) {
      if (state.selectedRequiredDocIdList.indexOf(requiredDocId) === -1) {
        state.selectedRequiredDocIdList.push(requiredDocId);
      }
    },
    allSelectedAppid(state) {
      state.selectedAppidList = state.filterdAppIdList.slice();
      state.selectedRequiredDocIdList = state.filterdIdList.slice();
    },
    initSelectedAppid(state) {
      state.selectedAppidList = [];
      state.selectedRequiredDocIdList = [];
    },
    initRequiredDocId(state) {
      state.selectedRequiredDocIdList = [];
    },
  },
  actions: {
  },
};
