// import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    status: null,
    reason: '',
    comments: {},
  },
  mutations: {
    updateStatus(state, value) {
      state.status = value;
    },
    updateReason(state, value) {
      state.reason = value;
    },
    updateComments(state, comments) {
      state.comments = comments;
    },
  },
  actions: {
  },
};
