import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_OPTION = {
  label: '',
  input_type: null,
  prefix: '',
  suffix: '',
  parent_option_id: null,
  parent_choice_id: null,
  view_template: null,
  selectedRuleIdList: [],
  selectedRuleParameters: {},
  choice_texts: [],
};

const INIT_FIELD = {
  id: null,
  group_id: 0,
  newGroupName: '',
  name: '',
  type: 1,
  label: '',
  // 解答欄増減フラグ
  is_variable: false,
  // 初期解答欄数
  input_number: 2,
  // 最小解答欄数or最小テーブル行数
  min_input_number: 2,
  // 最大解答欄数or最小テーブル行数
  max_input_number: 5,
  view_template: 1,
  options: [cloneDeep(INIT_OPTION)],
  _viewModalIndex: null,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_FIELD),
  mutations: {
    setDbData(state, data) {
      const { field } = data;
      state.id = field.id;
      state.group_id = field.group_id;
      state.type = field.type;
      state.name = field.name;
      state.label = field.label;
      state.is_variable = field.is_variable;
      state.input_number = field.input_number;
      state.min_input_number = field.min_input_number;
      state.max_input_number = field.max_input_number;
      state.view_template = field.view_template;

      const {
        options: dbOptions,
        choice_texts: choiceTexts,
      } = field;
      const { selectedRuleIdList, selectedRuleParameters } = data;

      const setOptions = [];
      dbOptions.forEach((dbOption, index) => {
        const setOption = {};
        Object.keys(INIT_OPTION).forEach((key) => {
          if (key !== 'selectedRuleIdList' && key !== 'selectedRuleParameters' && key !== 'choice_texts') {
            setOption[key] = dbOption[key];
          }
          if (key === 'choice_texts') {
            const textDataArray = choiceTexts.filter((tableText) => {
              return tableText.field_option_id === dbOption.id;
            });
            const texts = textDataArray.map((textData) => {
              return textData.text;
            });
            setOption.choice_texts = texts;
          }
        });
        // 設定ルールの取得
        if (typeof selectedRuleIdList[index] === 'undefined' || selectedRuleIdList[index].length < 1) {
          setOption.selectedRuleIdList = [];
        } else {
          setOption.selectedRuleIdList = selectedRuleIdList[index];
        }

        // 設定ルールのパラメータの取得
        if (selectedRuleParameters.length === 0) {
          setOption.selectedRuleParameters = {};
        } else {
          setOption.selectedRuleParameters = selectedRuleParameters;
        }

        setOptions.push(setOption);
      });
      state.options = setOptions;
    },
    updateName(state, value) {
      state.name = value;
    },
    updateType(state, value) {
      state.type = value;
    },
    updateGroup(state, value) {
      state.group = value;
    },
    updateNewGroupName(state, value) {
      state.newGroupName = value;
    },
    updateLabel(state, value) {
      state.label = value;
    },
    updateTemplate(state, value) {
      state.view_template = value;
    },
    addOption(state) {
      const newOption = cloneDeep(INIT_OPTION);
      state.options.push(newOption);
    },
    removeOption(state, index) {
      state.options.splice(index, 1);
    },
    updateOptionText(state, param) {
      Vue.set(state.choicesOption[param.index], 'text', param.value);
    },
    updateIsVariable(state, value) {
      state.is_variable = value;
    },
    updateInputNumber(state, value) {
      state.input_number = value;
    },
    updateMinInputNumber(state, value) {
      state.min_input_number = value;
    },
    updateMaxInputNumber(state, value) {
      state.max_input_number = value;
    },
    initField(state) {
      Object.assign(state, cloneDeep(INIT_FIELD));
    },
    initOptions(state, value = 1) {
      state.options = [];
      const newOption = cloneDeep(INIT_OPTION);
      for (let i = 0; i < value; i += 1) {
        state.options.push(newOption);
      }
    },
    updateViewModalIndex(state, index = null) {
      state._viewModalIndex = index;
    },
    // 入力ルールを一件選択
    addRule(state, ruleId) {
      const optionIndex = state._viewModalIndex;
      state.options[optionIndex].selectedRuleIdList.push(ruleId);
      state.options[optionIndex].selectedRuleIdList.sort((a, b) => a - b);
    },
    removeRule(state, ruleId) {
      const optionIndex = state._viewModalIndex;
      const index = state.options[optionIndex].selectedRuleIdList.indexOf(ruleId);
      if (index >= 0) {
        state.options[optionIndex].selectedRuleIdList.splice(index, 1);
      }
    },
    // ルール選択時にパラメータの入れ物を作成する
    addRuleInput(state, rule) {
      const optionIndex = state._viewModalIndex;
      const params = state.options[optionIndex].selectedRuleParameters;
      Vue.set(params, rule.id, new Array(rule.min_input_number));
    },
    removeRuleInput(state, rule) {
      const optionIndex = state._viewModalIndex;
      const params = state.options[optionIndex].selectedRuleParameters;
      Vue.delete(params, rule.id);
    },
    // ルールパラメータ
    updateRuleParameter(state, param) {
      const optionIndex = state._viewModalIndex;
      const params = state.options[optionIndex].selectedRuleParameters;
      const target = params[param.ruleId];
      target.splice(param.parameterIndex, 1, param.value);
    },
    updateChoiceTexts(state, param) {
      state.options[param.index].choice_texts = param.value;
    },
  },
  actions: {
    selectRule(context, rule) {
      context.commit('addRule', rule.id);
      context.commit('addRuleInput', rule);
    },
    unselectRule(context, rule) {
      context.commit('removeRule', rule.id);
      context.commit('removeRuleInput', rule);
    },
  },
};
