export default class {
  static getMonthOptions(defaultVal = '', defaultText = '---') {
    const monthOptions = [];
    const zero = '0';
    for (let i = 0; i <= 12; i += 1) {
      if (i === 0) {
        monthOptions.push({ value: defaultVal, text: defaultText });
        i += 1;
      }
      const month = (zero + i).slice(-2);
      monthOptions.push({ value: month, text: month });
    }
    return monthOptions;
  }

  static getDayOptions(defaultVal = '', defaultText = '---') {
    const dayOptions = [];
    const zero = '0';
    for (let i = 0; i <= 31; i += 1) {
      if (i === 0) {
        dayOptions.push({ value: defaultVal, text: defaultText });
        i += 1;
      }
      const day = (zero + i).slice(-2);
      dayOptions.push({ value: day, text: day });
    }
    return dayOptions;
  }

  static getTimeOptionArray(interval = 1, params = {}) {
    const timeOptions = [];
    const min = params.min || 0;
    const max = params.max || 24;
    for (let t = min; t < max; t += interval) {
      const strTime = t.toString();
      const option = {
        value: `00${strTime}`.slice(-2),
        text: `00${strTime}`.slice(-2),
      };
      timeOptions.push(option);
    }
    return timeOptions;
  }

  // 同上 分
  static getMinuteOptionArray(interval = 1, params = {}) {
    const timeOptions = [];
    const min = params.min || 0;
    const max = params.max || 60;
    for (let t = min; t < max; t += interval) {
      const strTime = t.toString();
      const option = {
        value: `00${strTime}`.slice(-2),
        text: `00${strTime}`.slice(-2),
      };
      timeOptions.push(option);
    }
    return timeOptions;
  }
}
