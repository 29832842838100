import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  selectedAppIdList: [],

  // 合否設定
  // フィルタリング条件保存
  judgmentFilter: {
    year: null,
    apptype: null,
    stage: null,
    bunri: '',
    pass: '',
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilter(state, param) {
      Vue.set(state.judgmentFilter, param.key, param.value);
    },
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.app_id;
      });
      state.filterdIdList = filterdIdList;
    },


    removeSelectedAppid(state, appId) {
      const index = state.selectedAppIdList.indexOf(appId);
      if (index !== -1) {
        state.selectedAppIdList.splice(index, 1);
      }
    },
    setSelectedAppid(state, list) {
      state.selectedAppIdList = list;
    },
    addSelectedAppid(state, appId) {
      if (state.selectedAppIdList.indexOf(appId) === -1) {
        state.selectedAppIdList.push(appId);
      }
    },
    allSelectedAppid(state) {
      state.selectedAppIdList = state.filterdIdList.slice();
    },
    initSelectedAppid(state) {
      state.selectedAppIdList = [];
    },
    initSearch(state) {
      state.selectedAppIdList = [];
      state.judgmentFilter = {
        year: null,
        apptype: null,
        stage: null,
        bunri: '',
        pass: '',
      };
    },
  },
  actions: {
  },
};
