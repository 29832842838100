import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    status: null,
    reason: '',
    adjusts: {},
    comments: {},
    isNoneSendMailStatus: false,
    changeHistoryList: {},
  },
  mutations: {
    updateStatus(state, value) {
      state.status = value;
    },
    updateReason(state, value) {
      state.reason = value;
    },
    updateAdjust(state, param) {
      Vue.set(state.adjusts[param.itemId], 'score', param.value);
    },
    updateComments(state, comments) {
      state.comments = comments;
    },
    updateChangeHistoryList(state, changeHistoryList) {
      state.changeHistoryList = changeHistoryList;
    },
    setDbEvalAdjusts(state, adjusts) {
      state.adjusts = adjusts;
    },
    updateIsNoneSendMailStatus(state, value) {
      state.isNoneSendMailStatus = value;
    },
  },
  actions: {
  },
};
