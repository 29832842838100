import Vue from 'vue';
import { cloneDeep } from 'lodash';
// import moment from 'moment';

const INIT_APPTYPE = {
  filterdIdList: [],
  selectedAppIdList: [],

  currentPage: 1,
  perPage: 50,
  totalRows: 0,
  search: {
    year: null,
    name: '',
    series: null,
    bunri: '',
    code: '',
    status: '',
    isChenged: null,
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdIdList = filterdIdList;
    },
    removeSelectedAppid(state, appId) {
      const index = state.selectedAppIdList.indexOf(appId);
      if (index !== -1) {
        state.selectedAppIdList.splice(index, 1);
      }
    },
    setSelectedAppid(state, list) {
      state.selectedAppIdList = list;
    },
    addSelectedAppid(state, appId) {
      if (state.selectedAppIdList.indexOf(appId) === -1) {
        state.selectedAppIdList.push(appId);
      }
    },
    allSelectedAppid(state) {
      state.selectedAppIdList = state.filterdIdList.slice();
    },
    initSelectedAppid(state) {
      state.selectedAppIdList = [];
    },
    // 申請管理
    setFilter(state, param) {
      Vue.set(state.search, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    initSearch(state) {
      state.search = {
        year: null,
        name: '',
        series: null,
        bunri: '',
        code: '',
        status: '',
        isChenged: null,
      };
      state.currentPage = 1;
      state.perPage = 50;
      state.totalRows = 0;
    },
  },
  actions: {
  },
};
