export default {
  namespaced: true,
  state: {
    login_id: null,
    token: null,
    userType: null,
    authes: [],
  },
  mutations: {
    create(state, data) {
      state.login_id = data.login_id;
      state.token = data.token;
      state.token_expiration = data.token_expiration;
      state.userType = Number(data.userType);
      state.authes = data.authes;
    },
    destroy(state) {
      state.login_id = null;
      state.token = null;
      state.token_expiration = null;
      state.userType = false;
      state.authes = [];
    },
    updateLoginId(state, value) {
      state.login_id = value;
    },
  },
  actions: {
    login(context, params) {
      context.commit('create', params);
      localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY,
        JSON.stringify(context.state));
    },
    logout(context) {
      context.commit('destroy');
      localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY);
    },
    authChange(context, params) {
      context.commit('updateLoginId', params);
      localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_AUTH_KEY,
        JSON.stringify(context.state));
    },
  },
};
