<template>
  <div class="menuLabel flex flexRight flexMiddle">
    <b-nav-item-dropdown text="申請管理"
      :class="menuName === 'uniApplication' ? 'activeNav' : ''">
      <b-dropdown-item
        @click="initGo('/university/applications/list', 'uniApplicationSearch')"
        to="/university/applications/list">申請管理</b-dropdown-item>
      <b-dropdown-item to="/university/applications/change/list">計画変更申請管理</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item-dropdown text="提出書類"
      :class="menuName === 'uniReport' ? 'activeNav' : ''">
      <b-dropdown-item to="/university/requiredDoc/list">助成手続き書類</b-dropdown-item>
      <b-dropdown-item to="/university/interimReps/list">進捗状況報告書</b-dropdown-item>
      <b-dropdown-item to="/university/reports/list">成果報告書</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item-dropdown text="メッセージ"
      :class="menuName === 'message' ? 'activeNav' : ''">
      <b-dropdown-item to="/university/message/create">メッセージ作成</b-dropdown-item>
      <b-dropdown-item
       @click="initGo('/university/message/inbox', 'uniMessageSearch')">送受信一覧</b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item
      :class="menuName === 'uniSetting' ? 'activeNav' : ''"
      to="/university/settings/edit">ログイン情報変更</b-nav-item>
    <span
      @click="kickLogout"
      class="logout px-3"
      >ログアウト</span>
  </div>
</template>

<script>
export default {
  name: 'UniHeader',
  props: {
    menuName: { type: String, default: '' },
  },
  methods: {
    initGo(path, store) {
      this.$store.commit(`${store}/initSearch`);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    kickLogout() {
      this.$emit('kickLogout');
    },
  },
};
</script>

<style scoped>
  .logout {
    border: solid;
    border-width: 0 0 0 1px;
    border-color: #ccc;
  }

  .logout:hover {
    cursor: pointer;
  }

  .menuLabel>li{
    list-style: none;
  }

</style>
