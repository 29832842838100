export default {
  namespaced: true,
  state: {
    rules: [],
    rulesEachType: [],
  },
  mutations: {
    setRule(state, rules) {
      state.rules = rules;
    },
    setEachRule(state, rulesEachType) {
      state.rulesEachType = rulesEachType;
    },
    destroy(state) {
      state.rules = [];
    },
  },
  // 非同期処理が使える
  actions: {
    async fetchRules(context, api) {
      await api.send('/api/contents/validation_rules')
        .then((response) => {
          context.commit('setRule', response.data.validation_rules);
          context.commit('setEachRule', response.data.rules_each_type);
          return true;
        })
        .catch(() => {
          context.commit('destroy');
          return false;// ?
        });
    },
  },
};
