// import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_APPTYPE = {
  id: null,
  year: null,
  name: '',
  recruitment_type: 1,
  application_announcement_datetime: null,
  open_datetime: null,
  close_datetime: null,
  result_announcement_datetime: null,
  requirements_file: null, // 新規アップロードしたファイルオブジェクト
  hasRequirementsFile: false, // 登録済みの募集要領ファイルがあるか
  updated_file: null,
  do_delete_file: false,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    initApptype(state) {
      Object.assign(state, cloneDeep(INIT_APPTYPE));
    },
    setDbData(state, apptype) {
      state.id = apptype.id;
      state.year = apptype.year;
      state.name = apptype.name;
      state.recruitment_type = apptype.recruitment_type;
      state.application_announcement_datetime = apptype.application_announcement_datetime;
      state.open_datetime = apptype.open_datetime;
      state.close_datetime = apptype.close_datetime;
      state.result_announcement_datetime = apptype.result_announcement_datetime;
      state.hasRequirementsFile = apptype.hasRequirementsFile;
    },
    updateName(state, value) {
      state.name = value;
    },
    updateYear(state, value) {
      state.year = value;
    },
    updateRequirementsFile(state, file) {
      state.requirements_file = file;
    },
    toggleDoDeleteFile(state) {
      state.do_delete_file = !state.do_delete_file;
    },
    updateApplicationAnnouncementDatetime(state, value) {
      state.application_announcement_datetime = value;
    },
    updateOpenDatetime(state, value) {
      state.open_datetime = value;
    },
    updateCloseDatetime(state, value) {
      state.close_datetime = value;
    },
    updateResultAnnouncementDatetime(state, value) {
      state.result_announcement_datetime = value;
    },
  },
  actions: {
  },
};
