export default class {
  /**
   * apiのpostでblobを受け取った際、ダウンロードを実行する。
   * @param {} blob response.data
   * @param {String} fileName
   */
  static blob(blob, fileName) {
    const url = URL.createObjectURL(new Blob([blob]));
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  }

  static csvUtf(blob, fileName) {
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const url = URL.createObjectURL(new Blob([bom, blob], { type: 'text/csv' }));
    // const url = URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
      // window.navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  }
}
