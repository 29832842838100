<template>
  <div id="error">
    <div id="errorWrap">
      <b-alert show variant="danger">
        <h4 class="alert-heading">エラー</h4>
        <hr>
        <p class="mb-0">{{defaultMsg}}</p>
        <div class="mt-3">
          <b-button @click="back">戻る</b-button>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {
      defaultMsg: 'エラーが発生しました。お手数ですがもう一度お試しください。',
    };
  },
  methods: {
    back() {
      // this.$router.push({ name: this.jumpComponent });
    },
  },
};
</script>

<style>
  #error {
    position: absolute;
    background-color:rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
