import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_DATA = {
  search: {
    name: '',
    mail_address: '',
    series: null,
  },
  filterdIdList: [],
  selectedIdList: [],
  currentPage: 1,
  perPage: 50,
  totalRows: 0,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_DATA),
  mutations: {
    setFilterdIdList(state, filterdData) {
      const filterdIdList = filterdData.map((data) => {
        return data.id;
      });
      state.filterdIdList = filterdIdList;
    },
    removeSelectedId(state, id) {
      const index = state.selectedIdList.indexOf(id);
      if (index !== -1) {
        state.selectedIdList.splice(index, 1);
      }
    },
    setSelectedId(state, list) {
      state.selectedIdList = list;
    },
    addSelectedId(state, id) {
      if (state.selectedIdList.indexOf(id) === -1) {
        state.selectedIdList.push(id);
      }
    },
    allSelectedId(state) {
      state.selectedIdList = state.filterdIdList.slice();
    },
    initSelectedId(state) {
      state.selectedIdList = [];
    },
    setFilter(state, param) {
      Vue.set(state.search, param.key, param.value);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    initSearch(state) {
      state.search = {
        name: '',
        mail_address: '',
        series: null,
      };
      state.currentPage = 1;
      state.perPage = 50;
      state.totalRows = 0;
      state.filterdIdList = [];
      state.selectedIdList = [];
    },
  },
  actions: {
  },
};
