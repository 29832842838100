<template>
  <b-container fluid :class="(errMessage.length)?'errItem itemWrap':'itemWrap'">
    <b-row :class="itemStyle">
      <b-col sm="5" :class="labelGridClass">
        <div :class="disabled?'badgeWrap pale':'badgeWrap'">
          <div v-if="isRequired" class="mr-2 badge badge-required">必須</div>
        </div>
        <div class="label">
          <label :class="disabled?labelSize+' gray':labelSize">{{ label }}</label>
        </div>
      </b-col>
      <b-col :sm="sm" class="p-20">
        <b-form-input
          :type="type"
          :placeholder="placeholder"
          :value="value"
          :size="size"
          :class="inputClass"
          :maxLength="maxLength"
          :disabled="disabled"
          @input="input"
          @blur="removeEventSet"
        >
        </b-form-input>
      </b-col>
      <b-col sm="2" class="pl-20" v-if="hasBtn">
        <b-button
          class="btn"
          @click="$emit('click')"
          :disabled="btnDisabled"
        >
          {{ btnText }}
        </b-button>
      </b-col>
    </b-row>
    <span class="error fw-3" v-if="errMessage.length">
      {{ errMessage[0] }}
    </span>
  </b-container>
</template>
<script>

export default {
  name: 'FormText',
  props: {
    label: {},
    itemStyle: {},
    value: {},
    inputClass: {},
    maxLength: {},
    sm: { type: String, default: '8' },
    errMessage: { default: () => [] },
    placeholder: { type: String },
    labelSize: { type: String, default: '' },
    size: { type: String, default: '' },
    type: { type: String, default: 'text' },
    labelGridClass: { default: 'labelWrap' },
    disabled: { type: Boolean, default: false },
    // ボタン関連
    hasBtn: { type: Boolean, default: false },
    btnDisabled: { type: Boolean, default: false },
    btnText: {},
    isRequired: { type: Boolean, default: false },
  },
  methods: {
    input(event) {
      this.$emit('input', event);
      this.$emit('check');
    },
    removeEventSet() {
      this.$emit('removeEvent');
    },
  },
};
</script>

<style scoped>
/* 共通Style */
label,
.labelWrap,
.inputLabel {
  width: 180px;
  max-width: 237px;
  font-weight: bold;
  white-space:pre-line;
}
input[type="text"],
input[type="password"] {
  width: 400px;
  height: 50px;
}
.error{
  font-size: 12.8px;
  color: #dc3545;
  display: block;
  width: 400px;
  margin-top: 3px;
}
/* ラベル + 下にテキストタイプ(itemStyle = "block") */
.block{
  display: block;
}
/* ラベル + 横並びテキストタイプ(itemStyle = "line") */
.line .labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.line .labelWrap .badgeWrap,
.line .labelWrap .label,
.inputLabel .label{
  display: inline-block;
}
.line .labelWrap .badgeWrap div,
.inputLabel .badgeWrap div{
  vertical-align: middle;
}
.itemWrap{
  margin:15px 0;
  width: 800px;
}
.line{
  align-items: center;
  margin:0 auto;
}
.line label{
  align-items:center;
  margin:0;
}
.line label{
  text-align: left;
}
.line + span{
  padding-left: 31.5%;
  text-align: left;
  margin-left:15px;
  width: 700px;
}
input[disabled]{
  border:solid 1px #F3F3F3 !important;
  background-color: #F3F3F3;
}
/* テキストサイズ(long or small) */
input[type="text"].w-long {
  width: 750px
}
input[type="text"].w-small {
  width: 190px
}
.line .p-20{
  padding:0 20px;
}
.line button{
  width: 100px;
  background-color: #122889 !important;
  color: #FFFFFF!important;
  font-size: 18px;
  font-weight: 600;
  margin-left: 40px;
}
label.gray{
  color: #BBBBBB;
}
div.pale .badge-required{
  opacity: 0.4;
}
</style>
