import Vue from 'vue';
import moment from 'moment';
import { cloneDeep } from 'lodash';

const INIT_USER_INFO = {
  // ユーザー情報
  inputData: {
    sei: '',
    mei: '',
    kana_sei: '',
    kana_mei: '',
    birth: null,
    institution: null,
  },
  errors: [],
  initData: [],
  isChange: false,
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_USER_INFO),
  mutations: {
    setDbData(state, user) {
      if (user) {
        Vue.set(state.inputData, 'sei', user.sei);
        Vue.set(state.inputData, 'mei', user.mei);
        Vue.set(state.inputData, 'kana_sei', user.kana_sei);
        Vue.set(state.inputData, 'kana_mei', user.kana_mei);
        Vue.set(state.inputData, 'birth', moment(user.birth).format('YYYY/MM/DD'));
        Vue.set(state.inputData, 'institution', user.institution);
        state.initData = JSON.parse(JSON.stringify(state.inputData));
      }
    },
    // ユーザー情報
    updateSei(state, value) {
      Vue.set(state.inputData, 'sei', value);
    },
    updateMei(state, value) {
      Vue.set(state.inputData, 'mei', value);
    },
    updateKanaSei(state, value) {
      Vue.set(state.inputData, 'kana_sei', value);
    },
    updateKanaMei(state, value) {
      Vue.set(state.inputData, 'kana_mei', value);
    },
    updateBirth(state, value) {
      Vue.set(state.inputData, 'birth', value);
    },
    updateInstitution(state, value) {
      Vue.set(state.inputData, 'institution', value);
    },
    setSavedData(state) {
      state.initData = JSON.parse(JSON.stringify(state.inputData));
    },
    // エラー関連
    setErrorMsg(state, errors) {
      state.errors = errors;
    },
    deleteErrMsg(state, target) {
      Vue.delete(state.errors, target);
    },
    resetErrorMsg(state) {
      state.errors = [];
    },
    changeCheck(state) {
      const nowData = JSON.stringify(state.inputData);
      const initData = JSON.stringify(state.initData);
      state.isChange = (nowData !== initData);
    },
    resetInputData(state) {
      state.inputData = JSON.parse(JSON.stringify(state.initData));
    },
  },
  actions: {
  },
};
