import { cloneDeep } from 'lodash';

const INIT_APPTYPE = {
  currentPage: 1,
  perPage: 50,
  totalRows: 0,
  search: {
    status: '',
    type: '',
    title: '',
    other_title: '',
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    initSearch(state) {
      state.search = {
        status: '',
        type: '',
        title: '',
        other_title: '',
      };
      state.currentPage = 1;
      state.perPage = 50;
      state.totalRows = 0;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, page) {
      state.perPage = page;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    setSearch(state, search) {
      state.search = {
        status: search.status,
        type: search.type,
        title: search.title,
        other_title: search.other_title,
      };
    },
  },
  actions: {
  },
};
