import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_APPTYPE = {
  filterdIdsList: [],

  // フィルタリング条件保存
  filter: {
    year: null,
    apptype: null,
    stageId: null,
    status: null,
  },
};

export default {
  namespaced: true,
  state: cloneDeep(INIT_APPTYPE),
  mutations: {
    setFilter(state, param) {
      Vue.set(state.filter, param.key, param.value);
    },
    setFilterdIdsList(state, filterdData) {
      const filterdIdsList = filterdData.map((data) => {
        return { appId: data.app_id, stageId: data.stage_id };
      });
      state.filterdIdsList = filterdIdsList;
    },
    initSearch(state) {
      Vue.set(state.filter, 'year', null);
      Vue.set(state.filter, 'apptype', null);
      Vue.set(state.filter, 'stageId', null);
      Vue.set(state.filter, 'status', null);
    },
  },
  actions: {
  },
};
