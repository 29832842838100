export default {
  methods: {
    confirm: async function(msg, showBg = true, option = {}) {// eslint-disable-line
      const setting = {
        okTitle: option.okTitle || 'OK',
        cancelTitle: option.cancelTitle || 'キャンセル',
        buttonSize: option.buttonSize || 'sm',
        footerClass: option.footerClass || 'dialogBtnWrap',
        bodyClass: option.bodyClass || 'dialogBody',
        hideBackdrop: option.hideBackdrop || !showBg,
      };
      const result = await this.$bvModal.msgBoxConfirm(msg, setting);
      return result;
    },
    alert: async function(msg, showBg = true, option = {}) {// eslint-disable-line
      const setting = {
        okTitle: option.okTitle || 'OK',
        buttonSize: option.buttonSize || 'sm',
        footerClass: option.footerClass || 'dialogBtnWrap',
        bodyClass: option.bodyClass || 'dialogBody',
        hideBackdrop: option.hideBackdrop || !showBg,
      };
      const result = await this.$bvModal.msgBoxOk(msg, setting);
      return result;
    },
  },
};
