<template>
  <div class="loader">
    <div id="loaderWrap" class="text-center">
      <b-spinner variant="primary" label="Large Spinner"
      style="width: 4rem; height: 4rem;"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped>
  .loader {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  };
</style>
