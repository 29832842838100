import Vue from 'vue';
import { cloneDeep } from 'lodash';
import moment from 'moment';
// import CONST_APPTYPE from '@/constants/apptypes';

const INIT_STAGE = {
  from: null,
  to: null,
};

const INIT_FILE = {
  uploadedFileKey: null, // ファイルの時のキー
  uploadedFileName: null,
  dbAssetId: null,
  dbFileName: null,
  do_delete_file: false,
  uploadedFiles: {},
  dbFiles: {},
  uploadedFileNames: [],
};

const INIT_EVALUATION = {
  id: null,
  name: '',
  type: null,
  param: '',
};

const INIT_CRITERION = {
  id: null,
  min_score: '',
  max_score: '',
  num: '',
};

const INIT_DATA = {
  apptypeId: null,
  seriesId: null,
  apptype: {
    application_announcement_datetime: null,
    open_datetime: null,
    close_datetime: null,
    result_announcement_datetime: null,
    required_doc_from: null,
    required_doc_to: null,
    interim_rep_from: null,
    interim_rep_to: null,
  },
  stages: [cloneDeep(INIT_STAGE)],
  // 論理ファイル:
  files: {},
  interimRepFiles: {},
  reportFiles: {},
  requiredDocFiles: {},
  changePlanFiles: {},
  // 物理ファイル入れ
  realFiles: {},
  evaluations: [cloneDeep(INIT_EVALUATION)],
  criteria: [],
  apptypeCloseYmd: null,
  requiredDocCloseYmd: null,
  interimRepCloseMd: null,
  appStageOpenDateListCloseYmd: {},
  // 一時アップロードファイル入れ
  tempUpFileList: {},
  upFileKeys: {},
};

function getEndTime(datetime) {
  if (!datetime) {
    return datetime;
  }
  const dt = moment(datetime);
  const ymdh = dt.add(1, 'hour').format('YYYY-MM-DD HH');
  return `${ymdh}:00:00`;
}

export default {
  namespaced: true,
  state: cloneDeep(INIT_DATA),
  mutations: {
    setDbData(state, dbData) {
      if (dbData === null || dbData.length === 0) {
        return;
      }
      state.apptypeId = dbData.id;
      const apptype = {
        application_announcement_datetime: dbData.application_announcement_datetime,
        open_datetime: dbData.open_datetime,
        close_datetime: getEndTime(dbData.close_datetime),
        result_announcement_datetime: dbData.result_announcement_datetime,
        required_doc_from: dbData.required_doc_from,
        required_doc_to: getEndTime(dbData.required_doc_to),
        interim_rep_from: dbData.interim_rep_from,
        interim_rep_to: dbData.interim_rep_to,
      };
      state.apptype = apptype;
      state.apptypeCloseYmd = dbData.close_datetime;
      state.requiredDocCloseYmd = dbData.required_doc_to;
      if (dbData.stages.length === 0) {
        return;
      }
      // 評価段階の最大数分だけ入れ物作成（データ上は一次審査なしだったりするので）
      const maxStage = Math.max.apply(null, dbData.stages.map((stage) => { return stage.stage; }));
      for (let i = 1; i < maxStage; i += 1) {
        state.stages.push(cloneDeep(INIT_STAGE));
      }
      dbData.stages.forEach((stage) => {
        const index = stage.stage - 1;
        Vue.set(state.stages[index], 'from', stage.from);
        Vue.set(state.stages[index], 'to', getEndTime(stage.to));
        Vue.set(state.appStageOpenDateListCloseYmd, index, stage.to);
      });
    },
    setOldYearData(state, dbData) {
      if (dbData === null || Object.keys(dbData).length === 0) {
        return;
      }
      if (state.apptype.interim_rep_from === null || state.apptype.interim_rep_from === '') {
        Vue.set(state.apptype, 'interim_rep_from', dbData.interim_rep_from);
      }
      if (state.apptype.interim_rep_to === null || state.apptype.interim_rep_to === '') {
        Vue.set(state.apptype, 'interim_rep_to', dbData.interim_rep_to);
      }
    },
    setApptypeCloseYmd(state, param) {
      state.apptypeCloseYmd = param;
    },
    setRequiredDocCloseYmd(state, param) {
      state.requiredDocCloseYmd = param;
    },
    setInterimRepCloseMd(state, param) {
      state.interimRepCloseMd = param;
    },
    setAppStageOpenDateListCloseYmd(state, params) {
      Vue.set(state.appStageOpenDateListCloseYmd, params.index, params.value);
    },
    setDbFileData(state, dbData) {
      if (dbData.app.length > 0) {
        dbData.app.forEach((fileData) => {
          const setDbFilesData = {};
          if (fileData.files.length > 0) {
            fileData.files.forEach((file, index) => {
              const setParams = {
                dbFileName: file.name,
                do_delete_file: false,
              };
              Vue.set(setDbFilesData, index, setParams);
            });
          }
          const param = {
            dbAssetId: fileData.id,
            dbFiles: setDbFilesData,
            do_delete_file: false,
            uploadedFileKey: `file${fileData.sort}`,
            uploadedFileName: null,
            uploadedFiles: {},
            uploadedFileNames: [],
          };
          Vue.set(state.files, `file${fileData.sort}`, param);
        });
      }

      if (dbData.changePlan && dbData.changePlan.length > 0) {
        dbData.changePlan.forEach((fileData) => {
          const setDbFilesData = {};
          if (fileData.files.length > 0) {
            fileData.files.forEach((file, index) => {
              const setParams = {
                dbFileName: file.name,
                do_delete_file: false,
              };
              Vue.set(setDbFilesData, index, setParams);
            });
          }
          const param = {
            dbAssetId: fileData.id,
            dbFileName: fileData.name,
            do_delete_file: false,
            uploadedFileKey: `changePlanFile${fileData.sort}`,
            uploadedFileName: null,
            dbFiles: setDbFilesData,
            uploadedFiles: {},
            uploadedFileNames: [],
          };
          Vue.set(state.changePlanFiles, `changePlanFile${fileData.sort}`, param);
        });
      }

      if (dbData.requiredDoc && dbData.requiredDoc.length > 0) {
        dbData.requiredDoc.forEach((fileData) => {
          const setDbFilesData = {};
          if (fileData.files.length > 0) {
            fileData.files.forEach((file, index) => {
              const setParams = {
                dbFileName: file.name,
                do_delete_file: false,
              };
              Vue.set(setDbFilesData, index, setParams);
            });
          }
          const param = {
            dbAssetId: fileData.id,
            dbFileName: fileData.name,
            do_delete_file: false,
            uploadedFileKey: `requiredDocFile${fileData.sort}`,
            uploadedFileName: null,
            dbFiles: setDbFilesData,
            uploadedFiles: {},
            uploadedFileNames: [],
          };
          Vue.set(state.requiredDocFiles, `requiredDocFile${fileData.sort}`, param);
        });
      }
      if (dbData.interimRep && dbData.interimRep.length > 0) {
        dbData.interimRep.forEach((fileData) => {
          const setDbFilesData = {};
          if (fileData.files.length > 0) {
            fileData.files.forEach((file, index) => {
              const setParams = {
                dbFileName: file.name,
                do_delete_file: false,
              };
              Vue.set(setDbFilesData, index, setParams);
            });
          }
          const param = {
            dbAssetId: fileData.id,
            dbFileName: fileData.name,
            do_delete_file: false,
            uploadedFileKey: `interimRepFile${fileData.sort}`,
            uploadedFileName: null,
            dbFiles: setDbFilesData,
            uploadedFiles: {},
            uploadedFileNames: [],
          };
          Vue.set(state.interimRepFiles, `interimRepFile${fileData.sort}`, param);
        });
      }
      if (dbData.report && dbData.report.length > 0) {
        dbData.report.forEach((fileData) => {
          const setDbFilesData = {};
          if (fileData.files.length > 0) {
            fileData.files.forEach((file, index) => {
              const setParams = {
                dbFileName: file.name,
                do_delete_file: false,
              };
              Vue.set(setDbFilesData, index, setParams);
            });
          }
          const param = {
            dbAssetId: fileData.id,
            dbFileName: fileData.name,
            do_delete_file: false,
            uploadedFileKey: `reportFile${fileData.sort}`,
            uploadedFileName: null,
            dbFiles: setDbFilesData,
            uploadedFiles: {},
            uploadedFileNames: [],
          };
          Vue.set(state.reportFiles, `reportFile${fileData.sort}`, param);
        });
      }
    },
    setDbEvalItems(state, dbData) {
      if (dbData.length === 0) {
        return;
      }
      state.evaluations = [];
      dbData.forEach((data) => {
        const item = {
          id: data.id,
          name: data.name,
          type: data.type,
          param: data.param,
        };
        state.evaluations.push(item);
      });
    },
    setDbCriteria(state, dbData) {
      state.criteria = [];
      if (dbData.length === 0) {
        return;
      }
      dbData.forEach((data) => {
        const item = {
          id: data.id,
          min_score: data.min_score,
          max_score: data.max_score,
          num: data.num,
        };
        state.criteria.push(item);
      });
    },
    setValue(state, param) {
      const { type, key, value } = param;
      state[type][key] = value;
    },
    updateApptypeAnnouncementDatetime(state, value) {
      Vue.set(state.apptype, 'application_announcement_datetime', value);
    },
    updateApptypeOpenDatetime(state, value) {
      Vue.set(state.apptype, 'open_datetime', value);
    },
    updateApptypeCloseDatetime(state, value) {
      Vue.set(state.apptype, 'close_datetime', value);
    },
    updateApptypeResultAnnouncementDatetime(state, value) {
      Vue.set(state.apptype, 'result_announcement_datetime', value);
    },
    updateApptypeRequiredDocFrom(state, value) {
      Vue.set(state.apptype, 'required_doc_from', value);
    },
    updateApptypeRequiredDocTo(state, value) {
      Vue.set(state.apptype, 'required_doc_to', value);
    },
    updateApptypeInterimRepFrom(state, value) {
      Vue.set(state.apptype, 'interim_rep_from', value);
    },
    updateApptypeInterimRepTo(state, value) {
      Vue.set(state.apptype, 'interim_rep_to', value);
    },
    // シリーズ別のファイル定義に応じた入れ物作成
    setInitFiles(state, fileParam) {
      if (!fileParam.defineFiles) {
        return;
      }
      const series = fileParam.seriesId;

      fileParam.defineFiles.SERIES_APP_FILES[series].forEach((defineFile) => {
        const initObject = cloneDeep(INIT_FILE);
        initObject.uploadedFileKey = defineFile.fileKey;
        Vue.set(state.files, defineFile.fileKey, initObject);
      });
      // 計画変更書類
      fileParam.defineFiles.SERIES_CHANGE_PLAN_FILES[series].forEach((defineFile) => {
        const initObject = cloneDeep(INIT_FILE);
        initObject.uploadedFileKey = defineFile.fileKey;
        Vue.set(state.changePlanFiles, defineFile.fileKey, initObject);
      });
      // 手続き書類
      fileParam.defineFiles.SERIES_REQUIRED_DOC_FILES[series].forEach((defineFile) => {
        const initObject = cloneDeep(INIT_FILE);
        initObject.uploadedFileKey = defineFile.fileKey;
        Vue.set(state.requiredDocFiles, defineFile.fileKey, initObject);
      });
      // 進捗状況報告
      fileParam.defineFiles.SERIES_INTERIM_FILES[series].forEach((defineFile) => {
        const initObject = cloneDeep(INIT_FILE);
        initObject.uploadedFileKey = defineFile.fileKey;
        Vue.set(state.interimRepFiles, defineFile.fileKey, initObject);
      });
      // 最終報告
      fileParam.defineFiles.SERIES_REPORT_FILES[series].forEach((defineFile) => {
        const initObject = cloneDeep(INIT_FILE);
        initObject.uploadedFileKey = defineFile.fileKey;
        Vue.set(state.reportFiles, defineFile.fileKey, initObject);
      });
    },
    init(state) {
      state.apptypeId = null;
      Object.keys(state.apptype).forEach((key) => {
        state.apptype[key] = null;
      });
      state.stages = [cloneDeep(INIT_STAGE)];
      // 物理ファイル入れ
      state.realFiles = {};
      state.evaluations = [cloneDeep(INIT_EVALUATION)];
    },
    updateStageFrom(state, param) {
      Vue.set(state.stages[param.index], 'from', param.value);
    },
    updateStageTo(state, param) {
      Vue.set(state.stages[param.index], 'to', param.value);
    },
    addStage(state) {
      const newStage = cloneDeep(INIT_STAGE);
      state.stages.push(newStage);
    },
    removeStage(state, index) {
      state.stages.splice(index, 1);
    },
    // ファイル
    uploadFile(state, param) {
      const data = cloneDeep(INIT_FILE);
      // 初期化前にデータの最大値を取得
      const oldMaxIndex = 0;
      // キー配列の初期化
      Vue.set(state.upFileKeys, param.uploadedFileKey, []);
      Vue.set(state.tempUpFileList, param.uploadedFileKey, {});
      Object.keys(param.uploadedFiles).forEach((key) => {
        let setIndex = oldMaxIndex + 1;
        if (key === '0') {
          data.uploadedFileName = param.uploadedFiles[key].name;
        } else {
          data.uploadedFileName += ', ';
          data.uploadedFileName += param.uploadedFiles[key].name;
          if (state.upFileKeys[param.uploadedFileKey].length !== 0) {
            const listMax = Math.max(...state.upFileKeys[param.uploadedFileKey]);
            setIndex = listMax + 1;
          }
        }
        data.uploadedFileNames.push(param.uploadedFiles[key].name);
        // 一時アップロードファイル
        const myUpFileKeys = state.upFileKeys[param.uploadedFileKey];
        myUpFileKeys.push(setIndex);
      });
      data.uploadedFileKey = param.uploadedFileKey;
      // 論理ファイル
      state.files[param.uploadedFileKey].uploadedFileKey = data.uploadedFileKey;
      state.files[param.uploadedFileKey].uploadedFileName = data.uploadedFileName;
      state.files[param.uploadedFileKey].uploadedFileNames = data.uploadedFileNames;
      // 物理ファイル
      Vue.set(state.realFiles, param.uploadedFileKey, param.uploadedFiles);
    },
    kickDeleteFile(state, param) {
      state.files[param.uploadedFileKey].uploadedFileNames.splice(param.targetIndex, 1);
      // 物理ファイル
      const dataTransfer = new DataTransfer();
      Object.keys(state.realFiles[param.uploadedFileKey]).forEach((key) => {
        if (Number(key) !== Number(param.targetIndex)) {
          dataTransfer.items.add(state.realFiles[param.uploadedFileKey][key]);
        }
      });
      Vue.set(state.realFiles, param.uploadedFileKey, dataTransfer.files);
      const itemNumber = cloneDeep(state.upFileKeys[param.uploadedFileKey][param.targetIndex]);
      state.upFileKeys[param.uploadedFileKey].splice(param.targetIndex, 1);
      if (state.tempUpFileList[param.uploadedFileKey] !== undefined) {
        Vue.delete(state.tempUpFileList[param.uploadedFileKey], itemNumber);
      }
    },
    // 進捗状況報告
    uploadInterimRepFile(state, param) {
      const data = cloneDeep(INIT_FILE);
      // 初期化前にデータの最大値を取得
      const oldMaxIndex = 0;
      // キー配列の初期化
      Vue.set(state.upFileKeys, param.uploadedFileKey, []);
      Vue.set(state.tempUpFileList, param.uploadedFileKey, {});
      Object.keys(param.uploadedFiles).forEach((key) => {
        let setIndex = oldMaxIndex + 1;
        if (key === '0') {
          data.uploadedFileName = param.uploadedFiles[key].name;
        } else {
          data.uploadedFileName += ', ';
          data.uploadedFileName += param.uploadedFiles[key].name;
          if (state.upFileKeys[param.uploadedFileKey].length !== 0) {
            const listMax = Math.max(...state.upFileKeys[param.uploadedFileKey]);
            setIndex = listMax + 1;
          }
        }
        data.uploadedFileNames.push(param.uploadedFiles[key].name);
        // 一時アップロードファイル
        const myUpFileKeys = state.upFileKeys[param.uploadedFileKey];
        myUpFileKeys.push(setIndex);
      });
      data.uploadedFileKey = param.uploadedFileKey;
      // 論理ファイル
      state.interimRepFiles[param.uploadedFileKey].uploadedFileKey = data.uploadedFileKey;
      state.interimRepFiles[param.uploadedFileKey].uploadedFileName = data.uploadedFileName;
      state.interimRepFiles[param.uploadedFileKey].uploadedFileNames = data.uploadedFileNames;
      // 物理ファイル
      Vue.set(state.realFiles, param.uploadedFileKey, param.uploadedFiles);
    },
    kickDeleteInterimRepFile(state, param) {
      state.interimRepFiles[param.uploadedFileKey].uploadedFileNames.splice(param.targetIndex, 1);
      // 物理ファイル
      const dataTransfer = new DataTransfer();
      Object.keys(state.realFiles[param.uploadedFileKey]).forEach((key) => {
        if (Number(key) !== Number(param.targetIndex)) {
          dataTransfer.items.add(state.realFiles[param.uploadedFileKey][key]);
        }
      });
      Vue.set(state.realFiles, param.uploadedFileKey, dataTransfer.files);
      const itemNumber = cloneDeep(state.upFileKeys[param.uploadedFileKey][param.targetIndex]);
      state.upFileKeys[param.uploadedFileKey].splice(param.targetIndex, 1);
      if (state.tempUpFileList[param.uploadedFileKey] !== undefined) {
        Vue.delete(state.tempUpFileList[param.uploadedFileKey], itemNumber);
      }
    },
    // 成果報告
    uploadReportFile(state, param) {
      const data = cloneDeep(INIT_FILE);
      // 初期化前にデータの最大値を取得
      const oldMaxIndex = 0;
      // キー配列の初期化
      Vue.set(state.upFileKeys, param.uploadedFileKey, []);
      Vue.set(state.tempUpFileList, param.uploadedFileKey, {});
      Object.keys(param.uploadedFiles).forEach((key) => {
        let setIndex = oldMaxIndex + 1;
        if (key === '0') {
          data.uploadedFileName = param.uploadedFiles[key].name;
        } else {
          data.uploadedFileName += ', ';
          data.uploadedFileName += param.uploadedFiles[key].name;
          if (state.upFileKeys[param.uploadedFileKey].length !== 0) {
            const listMax = Math.max(...state.upFileKeys[param.uploadedFileKey]);
            setIndex = listMax + 1;
          }
        }
        data.uploadedFileNames.push(param.uploadedFiles[key].name);
        // 一時アップロードファイル
        const myUpFileKeys = state.upFileKeys[param.uploadedFileKey];
        myUpFileKeys.push(setIndex);
      });
      data.uploadedFileKey = param.uploadedFileKey;
      // 論理ファイル
      state.reportFiles[param.uploadedFileKey].uploadedFileKey = data.uploadedFileKey;
      state.reportFiles[param.uploadedFileKey].uploadedFileName = data.uploadedFileName;
      state.reportFiles[param.uploadedFileKey].uploadedFileNames = data.uploadedFileNames;
      // 物理ファイル
      Vue.set(state.realFiles, param.uploadedFileKey, param.uploadedFiles);
    },
    kickDeleteReportFile(state, param) {
      state.reportFiles[param.uploadedFileKey].uploadedFileNames.splice(param.targetIndex, 1);
      // 物理ファイル
      const dataTransfer = new DataTransfer();
      Object.keys(state.realFiles[param.uploadedFileKey]).forEach((key) => {
        if (Number(key) !== Number(param.targetIndex)) {
          dataTransfer.items.add(state.realFiles[param.uploadedFileKey][key]);
        }
      });
      Vue.set(state.realFiles, param.uploadedFileKey, dataTransfer.files);
      const itemNumber = cloneDeep(state.upFileKeys[param.uploadedFileKey][param.targetIndex]);
      state.upFileKeys[param.uploadedFileKey].splice(param.targetIndex, 1);
      if (state.tempUpFileList[param.uploadedFileKey] !== undefined) {
        Vue.delete(state.tempUpFileList[param.uploadedFileKey], itemNumber);
      }
    },
    // 助成手続き書類
    uploadRequiredDocFile(state, param) {
      const data = cloneDeep(INIT_FILE);
      // 初期化前にデータの最大値を取得
      const oldMaxIndex = 0;
      // キー配列の初期化
      Vue.set(state.upFileKeys, param.uploadedFileKey, []);
      Vue.set(state.tempUpFileList, param.uploadedFileKey, {});
      Object.keys(param.uploadedFiles).forEach((key) => {
        let setIndex = oldMaxIndex + 1;
        if (key === '0') {
          data.uploadedFileName = param.uploadedFiles[key].name;
        } else {
          data.uploadedFileName += ', ';
          data.uploadedFileName += param.uploadedFiles[key].name;
          if (state.upFileKeys[param.uploadedFileKey].length !== 0) {
            const listMax = Math.max(...state.upFileKeys[param.uploadedFileKey]);
            setIndex = listMax + 1;
          }
        }
        data.uploadedFileNames.push(param.uploadedFiles[key].name);
        // 一時アップロードファイル
        const myUpFileKeys = state.upFileKeys[param.uploadedFileKey];
        myUpFileKeys.push(setIndex);
      });
      data.uploadedFileKey = param.uploadedFileKey;
      // 論理ファイル
      state.requiredDocFiles[param.uploadedFileKey].uploadedFileKey = data.uploadedFileKey;
      state.requiredDocFiles[param.uploadedFileKey].uploadedFileName = data.uploadedFileName;
      state.requiredDocFiles[param.uploadedFileKey].uploadedFileNames = data.uploadedFileNames;
      // 物理ファイル
      Vue.set(state.realFiles, param.uploadedFileKey, param.uploadedFiles);
    },
    kickDeleteRequiredDocFile(state, param) {
      state.requiredDocFiles[param.uploadedFileKey].uploadedFileNames.splice(param.targetIndex, 1);
      // 物理ファイル
      const dataTransfer = new DataTransfer();
      Object.keys(state.realFiles[param.uploadedFileKey]).forEach((key) => {
        if (Number(key) !== Number(param.targetIndex)) {
          dataTransfer.items.add(state.realFiles[param.uploadedFileKey][key]);
        }
      });
      Vue.set(state.realFiles, param.uploadedFileKey, dataTransfer.files);
      const itemNumber = cloneDeep(state.upFileKeys[param.uploadedFileKey][param.targetIndex]);
      state.upFileKeys[param.uploadedFileKey].splice(param.targetIndex, 1);
      if (state.tempUpFileList[param.uploadedFileKey] !== undefined) {
        Vue.delete(state.tempUpFileList[param.uploadedFileKey], itemNumber);
      }
    },
    // 計画変更書類
    uploadChangePlanFile(state, param) {
      const data = cloneDeep(INIT_FILE);
      // 初期化前にデータの最大値を取得
      const oldMaxIndex = 0;
      // キー配列の初期化
      Vue.set(state.upFileKeys, param.uploadedFileKey, []);
      Vue.set(state.tempUpFileList, param.uploadedFileKey, {});
      Object.keys(param.uploadedFiles).forEach((key) => {
        let setIndex = oldMaxIndex + 1;
        if (key === '0') {
          data.uploadedFileName = param.uploadedFiles[key].name;
        } else {
          data.uploadedFileName += ', ';
          data.uploadedFileName += param.uploadedFiles[key].name;
          if (state.upFileKeys[param.uploadedFileKey].length !== 0) {
            const listMax = Math.max(...state.upFileKeys[param.uploadedFileKey]);
            setIndex = listMax + 1;
          }
        }
        data.uploadedFileNames.push(param.uploadedFiles[key].name);
        // 一時アップロードファイル
        const myUpFileKeys = state.upFileKeys[param.uploadedFileKey];
        myUpFileKeys.push(setIndex);
      });
      data.uploadedFileKey = param.uploadedFileKey;
      // 論理ファイル
      state.changePlanFiles[param.uploadedFileKey].uploadedFileKey = data.uploadedFileKey;
      state.changePlanFiles[param.uploadedFileKey].uploadedFileName = data.uploadedFileName;
      state.changePlanFiles[param.uploadedFileKey].uploadedFileNames = data.uploadedFileNames;
      // 物理ファイル
      Vue.set(state.realFiles, param.uploadedFileKey, param.uploadedFiles);
    },
    kickDeleteChangePlanFile(state, param) {
      state.changePlanFiles[param.uploadedFileKey].uploadedFileNames.splice(param.targetIndex, 1);
      // 物理ファイル
      const dataTransfer = new DataTransfer();
      Object.keys(state.realFiles[param.uploadedFileKey]).forEach((key) => {
        if (Number(key) !== Number(param.targetIndex)) {
          dataTransfer.items.add(state.realFiles[param.uploadedFileKey][key]);
        }
      });
      Vue.set(state.realFiles, param.uploadedFileKey, dataTransfer.files);
      const itemNumber = cloneDeep(state.upFileKeys[param.uploadedFileKey][param.targetIndex]);
      state.upFileKeys[param.uploadedFileKey].splice(param.targetIndex, 1);
      if (state.tempUpFileList[param.uploadedFileKey] !== undefined) {
        Vue.delete(state.tempUpFileList[param.uploadedFileKey], itemNumber);
      }
    },
    // 評価項目
    updateEvalName(state, param) {
      Vue.set(state.evaluations[param.index], 'name', param.value);
    },
    updateEvalType(state, param) {
      Vue.set(state.evaluations[param.index], 'type', param.value);
    },
    updateEvalParam(state, param) {
      Vue.set(state.evaluations[param.index], 'param', param.value);
    },
    addEvaluation(state) {
      state.evaluations.push(cloneDeep(INIT_EVALUATION));
    },
    removeEvaluation(state, index) {
      state.evaluations.splice(index, 1);
    },
    // 基準件数
    updateCriterionMin(state, param) {
      Vue.set(state.criteria[param.index], 'min_score', param.value);
    },
    updateCriterionMax(state, param) {
      Vue.set(state.criteria[param.index], 'max_score', param.value);
    },
    updateCriterionNum(state, param) {
      Vue.set(state.criteria[param.index], 'num', param.value);
    },
    addCriterion(state) {
      state.criteria.push(cloneDeep(INIT_CRITERION));
    },
    removeCriterion(state, index) {
      state.criteria.splice(index, 1);
    },
    setSeries(state, value) {
      state.seriesId = value;
    },
    delRegFile(state, param) {
      state.files[param.uploadedFileKey].dbFiles[param.delTargetKey].do_delete_file = true;
    },
    delRegInterimRepFile(state, param) {
      state.interimRepFiles[param.uploadedFileKey].dbFiles[param.delTargetKey].do_delete_file = true;
    },
    delRegReportFile(state, param) {
      state.reportFiles[param.uploadedFileKey].dbFiles[param.delTargetKey].do_delete_file = true;
    },
    delRegRequiredDocFile(state, param) {
      state.requiredDocFiles[param.uploadedFileKey].dbFiles[param.delTargetKey].do_delete_file = true;
    },
    delRegChangePlanFile(state, param) {
      state.changePlanFiles[param.uploadedFileKey].dbFiles[param.delTargetKey].do_delete_file = true;
    },

    setRegEndFile(state, param) {
      if (!param.uploadedFileName) {
        return;
      }
      const regEndFiles = param.uploadedFileName.split(',');
      // 同file名のものがないかチェック
      Object.keys(param.dbFiles).forEach((key) => {
        const result = regEndFiles.indexOf(param.dbFiles[key].dbFileName);
        if (result !== -1) {
          state.files[param.uploadedFileKey].dbFiles[key].do_delete_file = false;
          regEndFiles.splice(result, 1);
        }
      });
      // 登録済みに追加
      Object.keys(regEndFiles).forEach((key) => {
        const setParams = {
          dbFileName: regEndFiles[key],
          do_delete_file: false,
        };
        const setIndex = Object.keys(param.dbFiles).length + 1;
        Vue.set(state.files[param.uploadedFileKey].dbFiles, setIndex, setParams);
      });

      state.files[param.uploadedFileKey].uploadedFileName = null;
      Vue.delete(state.realFiles, param.uploadedFileKey);
    },
    setRegEndInterimRepFile(state, param) {
      if (!param.uploadedFileName) {
        return;
      }
      const regEndFiles = param.uploadedFileName.split(',');
      // 同file名のものがないかチェック
      Object.keys(param.dbFiles).forEach((key) => {
        const result = regEndFiles.indexOf(param.dbFiles[key].dbFileName);
        if (result !== -1) {
          state.interimRepFiles[param.uploadedFileKey].dbFiles[key].do_delete_file = false;
          regEndFiles.splice(result, 1);
        }
      });
      // 登録済みに追加
      Object.keys(regEndFiles).forEach((key) => {
        const setParams = {
          dbFileName: regEndFiles[key],
          do_delete_file: false,
        };
        const setIndex = Object.keys(param.dbFiles).length + 1;
        Vue.set(state.interimRepFiles[param.uploadedFileKey].dbFiles, setIndex, setParams);
      });

      state.interimRepFiles[param.uploadedFileKey].uploadedFileName = null;
      Vue.delete(state.realFiles, param.uploadedFileKey);
    },
    setRegEndReportFile(state, param) {
      if (!param.uploadedFileName) {
        return;
      }
      const regEndFiles = param.uploadedFileName.split(',');
      // 同file名のものがないかチェック
      Object.keys(param.dbFiles).forEach((key) => {
        const result = regEndFiles.indexOf(param.dbFiles[key].dbFileName);
        if (result !== -1) {
          state.reportFiles[param.uploadedFileKey].dbFiles[key].do_delete_file = false;
          regEndFiles.splice(result, 1);
        }
      });
      // 登録済みに追加
      Object.keys(regEndFiles).forEach((key) => {
        const setParams = {
          dbFileName: regEndFiles[key],
          do_delete_file: false,
        };
        const setIndex = Object.keys(param.dbFiles).length + 1;
        Vue.set(state.reportFiles[param.uploadedFileKey].dbFiles, setIndex, setParams);
      });

      state.reportFiles[param.uploadedFileKey].uploadedFileName = null;
      Vue.delete(state.realFiles, param.uploadedFileKey);
    },
    setRegEndRequiredDocFile(state, param) {
      if (!param.uploadedFileName) {
        return;
      }
      const regEndFiles = param.uploadedFileName.split(',');
      // 同file名のものがないかチェック
      Object.keys(param.dbFiles).forEach((key) => {
        const result = regEndFiles.indexOf(param.dbFiles[key].dbFileName);
        if (result !== -1) {
          state.requiredDocFiles[param.uploadedFileKey].dbFiles[key].do_delete_file = false;
          regEndFiles.splice(result, 1);
        }
      });
      // 登録済みに追加
      Object.keys(regEndFiles).forEach((key) => {
        const setParams = {
          dbFileName: regEndFiles[key],
          do_delete_file: false,
        };
        const setIndex = Object.keys(param.dbFiles).length + 1;
        Vue.set(state.requiredDocFiles[param.uploadedFileKey].dbFiles, setIndex, setParams);
      });

      state.requiredDocFiles[param.uploadedFileKey].uploadedFileName = null;
      Vue.delete(state.realFiles, param.uploadedFileKey);
    },
    setRegEndChangePlanFile(state, param) {
      if (!param.uploadedFileName) {
        return;
      }
      const regEndFiles = param.uploadedFileName.split(',');
      // 同file名のものがないかチェック
      Object.keys(param.dbFiles).forEach((key) => {
        const result = regEndFiles.indexOf(param.dbFiles[key].dbFileName);
        if (result !== -1) {
          state.changePlanFiles[param.uploadedFileKey].dbFiles[key].do_delete_file = false;
          regEndFiles.splice(result, 1);
        }
      });
      // 登録済みに追加
      Object.keys(regEndFiles).forEach((key) => {
        const setParams = {
          dbFileName: regEndFiles[key],
          do_delete_file: false,
        };
        const setIndex = Object.keys(param.dbFiles).length + 1;
        Vue.set(state.changePlanFiles[param.uploadedFileKey].dbFiles, setIndex, setParams);
      });

      state.changePlanFiles[param.uploadedFileKey].uploadedFileName = null;
      Vue.delete(state.realFiles, param.uploadedFileKey);
    },
    saveTempPath(state, params) {
      if (state.tempUpFileList[params.target] === undefined) {
        Vue.set(state.tempUpFileList, params.target, {});
      }
      Vue.set(state.tempUpFileList[params.target], params.subKey, params.path);
    },
  },
  actions: {
  },
};
