import Vue from 'vue';
import { cloneDeep } from 'lodash';

const INIT_PAGE = {
  name: '',
  selectedField: [],
  fieldRule: {},
  // ページ内で選択中の記入項目IDリスト
  selectedFieldIdList: [],
};

export default {
  namespaced: true,
  state: {
    pages: [cloneDeep(INIT_PAGE)],
    fieldName: '',
    fieldList: [],
    // 選択可能な、フィルタリングの結果に合う記入項目IDのリスト
    filterdFieldIdList: [],
    fieldGroupList: [],
    fieldGroup: null,
    fieldType: null,
  },
  mutations: {
    setDbData(state, dbPages) {
      state.pages = [];
      dbPages.forEach((dbPage) => {
        state.pages.push(dbPage);
      });
    },
    addPage(state) {
      state.pages.push(cloneDeep(INIT_PAGE));
    },
    removePage(state, index) {
      state.pages.splice(index, 1);
    },
    setFieldList(state, fieldList) {
      state.fieldList = fieldList;
    },
    setFieldGroupList(state, fieldGroupList) {
      state.fieldGroupList = fieldGroupList;
    },
    setFilterdFieldIdList(state, filterdFieldIdList) {
      state.filterdFieldIdList = filterdFieldIdList;
    },
    updateFieldName(state, value) {
      state.fieldName = value;
    },
    updateFieldGroup(state, value) {
      state.fieldGroup = value;
    },
    updateFieldType(state, value) {
      state.fieldType = value;
    },
    updateName(state, param) {
      Vue.set(state.pages[param.index], 'name', param.value);
    },
  },
  actions: {
    filterFieldId(context) {
      // 選択済みのIDリスト作成
      let allSecectIdList = [];
      Object.keys(context.state.pages).forEach((index) => {
        allSecectIdList = allSecectIdList.concat(context.state.pages[index].selectedFieldIdList);
      });
      const {
        fieldList, fieldName, fieldType, fieldGroup,
      } = context.state;
      const filterdFieldIdList = [];
      if (fieldName === '' && fieldType === null && fieldGroup === null) {
        context.commit('setFilterdFieldIdList', []);
      } else {
        Object.keys(fieldList).forEach((key) => {
          const field = fieldList[key];
          // まだ選択されていないものかどうかチェック
          const unselected = !allSecectIdList.includes(field.id);
          // 検索項目と一致する
          const sameField = fieldName === '' || field.name.includes(fieldName);
          const sameType = fieldType === null || field.type === fieldType;
          const sameGroup = fieldGroup === null || fieldGroup === field.group_id;
          if (unselected && sameField && sameType && sameGroup) {
            filterdFieldIdList.push(field.id);
          }
        });
        context.commit('setFilterdFieldIdList', filterdFieldIdList);
      }
    },
  },
};
