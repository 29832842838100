<template>
  <div :class="errMessage.length? 'err-datetime':''">
    <b-row :class="itemStyle">
      <b-col sm="3" :class="labelClass + ' labelWrap'">
        <div class="badgeWrap" v-if="hasBadge">
          <div v-if="isRequired" class="mr-2 badge badge-required">必須</div>
          <div v-if="!isRequired" class="mr-2 badge badge-optional">任意</div>
        </div>
        <div class="label"><label>{{ label }}</label></div>
      </b-col>
      <calendar
        :name="name"
        :value="value"
        :format="DatePickerFormat"
        :language="ja"
        :typeable="true"
        :input-class=" 'calendar ' + inputClass"
        ref="picker"
        :calendar-button="true"
        calendar-button-icon="calendar-hide-button"
        :disabled="disabled"
        :disabledDates="disabledDates"
        @selected="dateSelected"
      >
      </calendar>
      <unicon name="calendar-alt"
        fill="#495057"
        class="calendar-icon"
        ></unicon>
    </b-row>
    <div
      v-if="!noMsg && errMessage.length"
      class="error-wrap">
      {{ errMessage[0] }}
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import datepicker from 'vuejs-datepicker';
import { ja } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'CalendarBox',
  data() {
    return {
      DatePickerFormat: 'yyyy/MM/dd',
      ja,
      gender: '',
    };
  },
  components: {
    calendar: datepicker,
  },
  props: {
    label: {},
    itemStyle: {},
    value: {},
    inputClass: {},
    disabled: { type: Boolean, default: false },
    disabledDates: {},
    errMessage: {},
    name: { type: String, require: true },
    labelClass: { type: String, default: 'right' },
    isRequired: { type: Boolean, default: false },
    hasBadge: { type: Boolean, default: false },
    noMsg: { type: Boolean, default: false },
  },
  methods: {
    dateSelected(event) {
      this.$emit('input', moment(event).format('YYYY/MM/DD'));
    },
  },
};
</script>
<!-- カレンダーはscopedのcssが当たらないもよう -->
<style>
.line input.calendar{
  height: 50px;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
input.w-small{
  width:175px;
}
.calendar-icon {
  position: relative;
  left: -32px;
  display: block;
  right: 1px;
}
.err-datetime input.calendar{
  border:solid 1px #dc3545 !important;
}
.pickerDateForm::-ms-clear {
    visibility:hidden !important;
}
.calendar-hide-button {
  margin-left: 170px;
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  right: 1px;
  z-index: 1;
}
</style>
<style scoped>
.line .labelWrap label{
  width: 180px;
}
.line .labelWrap{
  width: 300px;
  min-width: 300px;
}
.line{
  align-items: center;
  margin:10px 0 !important;
}
.line label{
  align-items:center;
  margin:0;
}
.line .right{
  text-align: right;
}
.line .labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.line .labelWrap .badgeWrap,
.line .labelWrap .label{
  display: inline-block;
}
.line .labelWrap .badgeWrap div{
  vertical-align: middle;
}
.error-wrap{
  display:block;
  text-align: left;
  padding-left: 330px;
  margin-top: -6px;
  font-size: 80%;
  color: #dc3545;
}

</style>
